import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import routes from "@/routes";
import { lazy } from "react";
import AbmRoutes from "./abm.new.routes";
import AttributionRoutes from "./attribution.routes";
import CampaignStatsRoutes from "./campaignStats.routes";
import LeadAppendRoutes from "./leadAppend.routes";
import ReportsSetupRoutes from "./reportsSetup.routes";
const Report = lazy(() => import("@/modules/Report"));

const ReportRoutes = (permission: IPermissions, isAdmin: boolean) => {
  const children = [];
  if (isAdmin || permission[MODULES.CAMPAIGN_STATS]) {
    children.push(CampaignStatsRoutes);
  }
  if (isAdmin || permission[MODULES.ABM_STATS]) {
    children.push(AbmRoutes);
  }
  if (isAdmin || permission[MODULES.REPORTS_SETUP]) {
    children.push(ReportsSetupRoutes);
  }
  if (isAdmin || permission[MODULES.ATTRIBUTION]) {
    children.push(AttributionRoutes);
  }
  //FIXME: replace REPORTS_SETUP permission with Lead Append
  if (isAdmin || permission[MODULES.REPORTS_SETUP]) {
    children.push(LeadAppendRoutes);
  }

  return {
    element: <Report />,
    path: routes.REPORTS.INDEX,
    children: children,
  };
};

export default ReportRoutes;
