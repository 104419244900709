import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { CustomDialogProps } from "../dialog.interface";

export default function AlertDialog({
  dialogState,
  setDialogState,
}: CustomDialogProps) {
  const handleClose = () => {
    setDialogState({ open: false, title: "" });
  };
  return (
    <Dialog
      open={Boolean(dialogState.open && dialogState.title)}
      onClose={handleClose}
      fullWidth
      sx={{
        ".MuiDialog-paper": {
          maxWidth: "512px",
          height: "300px",
        },
        ...(dialogState.styles ?? {}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon
            sx={{ height: "150px", width: "150px" }}
            color="secondary"
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="h3Semi">{dialogState.title}</Typography>

          <Typography variant="body1Reg" color="common.black">
            {dialogState.children}
          </Typography>
        </Box>

        <DialogActions>
          <CustomLoadingButton
            isLoading={false}
            title="dismiss"
            onClick={handleClose}
          />
          <CustomLoadingButton
            isLoading={dialogState.buttonLoading ?? false}
            title={dialogState.buttonName ?? "Approve"}
            onClick={() =>
              dialogState.buttonFunction?.(dialogState?.buttonFunctionParams)
            }
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
