import { setAuthHeader } from "@/apis/client";
import { completeProfileOrUpdate, getTokenResponse } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import { getFirstTabPath, permittedTabs } from "@/components/Drawer/data";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import { TOKEN_KEY, USER_KEY, USER_TYPE } from "@/constants";
import { useUserContext } from "@/context/UserContext";
import routes from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

type ICompleteProfile = yup.InferType<typeof validationSchema>;
type Error = {
  errMsg: string;
};
export default function CompleteProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  if (!token) {
    navigate(routes.LOGIN);
  }
  const { data } = useQuery(["profile", token], getTokenResponse, {
    meta: {
      token,
    },
    onSuccess: (data) => {
      if (!data) navigate(routes.LOGIN);
    },
  });
  const { user, setUser, setToken, isAuthenticated } = useUserContext();
  const { mutateAsync, isLoading, error } = useMutation(
    completeProfileOrUpdate
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICompleteProfile>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  async function onFormSubmit(payload: ICompleteProfile) {
    const res = await mutateAsync({
      id: data.id,
      payload: {
        password: payload.password,
      },
    });

    window.localStorage.setItem(TOKEN_KEY, res.token);
    window.localStorage.setItem(USER_KEY, JSON.stringify(res.user));
    setAuthHeader(res.token);
    setUser(res.user);
    setToken(res.token);
  }
  if (isAuthenticated) {
    const tabs =
      user &&
      permittedTabs(
        user?.Role?.$permissions,
        user?.Role?.name === USER_TYPE.SUPER_ADMIN
      );
    return <Navigate to={routes.DASHBOARD + "/" + getFirstTabPath(tabs)} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: 13,
      }}
    >
      <Box>
        <FormHelperText error>{(error as Error)?.errMsg}</FormHelperText>
      </Box>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Complete Profile
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onFormSubmit)}>
        <CustomInput
          name="password"
          label="Password"
          inputType="password"
          control={control}
          error={errors?.password?.message}
        />
        <CustomInput
          name="confirmPassword"
          label="Confirm Password"
          inputType="password"
          control={control}
          error={errors?.confirmPassword?.message}
        />

        <CustomLoadingButton
          type="submit"
          title="Update"
          isLoading={isLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        />
      </Box>
    </Box>
  );
}

const validationSchema = yup.object({
  password: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
