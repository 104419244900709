import { secondaryStyles } from "@/components/Form/InputFields/secondaryStyles";
import { formFieldError, InputFieldType } from "@/types";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useController, UseControllerProps } from "react-hook-form";

export default function CustomDatePicker({
  name,
  control,
  label = null,
  error,
  datePickerProps,
  type = "primary",
  required = false,
  ...rest
}: CustomDatePickerProps) {
  const theme = useTheme();
  const { field } = useController({
    control,
    name,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={field.value ? field.value : null}
        onChange={(newValue) => {
          field.onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            fullWidth
            helperText={error && typeof error === "string" ? error : ""}
            FormHelperTextProps={{
              error: true,
            }}
            sx={{
              ...secondaryStyles(type, theme, false, true),
            }}
          />
        )}
        {...datePickerProps}
        {...rest}
      />
    </LocalizationProvider>
  );
}

interface CustomDatePickerProps extends UseControllerProps {
  label?: string | null;
  error?: formFieldError;
  datePickerProps?: any;
  type?: InputFieldType;
  required?: boolean;
  disabled?: boolean;
}
