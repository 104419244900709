import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export const createLeadGenForm = <TDATA>(payload: TDATA) => {
  return client.post("/leadGenForms", payload);
};

export const getPredefinedQuestions = <TDATA>(): Promise<TDATA> => {
  return client.get(`/leadGenForms/predefinedQuestions`);
};
export const getLeadGenForm = <TDATA>(id: any): Promise<TDATA> => {
  return client.get(`/leadGenForms/${id}`);
};
export const getAllLeadGenForm = <TDATA>(
  data: QueryFunctionContext
): Promise<TDATA> => {
  return client.get(
    appendQueryParams(`/leadGenForms`, data.meta?.queryParams ?? {})
  );
};

export function getObjectiveTypes() {
  return client.get(`/linkedin/objectiveTypes`);
}

export const createLinkedinLeadGenForm = (id: number) => {
  return client.post(`/leadGenForms/linkedin/${id}/run`);
};
