import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";
import appendQueryParams from "@/utils/appendQueryParams";

export async function getCompanies(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/companies`, data.meta?.queryParams ?? {})
  );
}
export function createAgencyOrAdvertiser({ payload }: any) {
  return client.post("/companies", payload);
}
export function editAgencyOrAdvertiser({
  companyId,
  payload,
}: {
  companyId: string | number;
  payload: any;
}) {
  return client.put(`/update/company/${companyId}`, payload);
}
export function getCompanyById(data: QueryFunctionContext) {
  return client.get(`/companies/${data?.meta?.id}`);
}

export function deleteCompany({ companyId }: { companyId: any }) {
  return client.delete(`/company/${companyId}`);
}
