import routes from "@/routes";
import { lazy } from "react";
const SiteVisiterAudience = lazy(
  () => import("@/modules/Audience/SiteVisitorAudience")
);
const ListSiteVisitorAudience = lazy(
  () => import("@/modules/Audience/SiteVisitorAudience/ListSiteVisitorAudience")
);
const CreateSiteVisitorAudience = lazy(
  () =>
    import("@/modules/Audience/SiteVisitorAudience/CreateSiteVisitorAudience")
);

const SiteVisitorAudienceRoutes = {
  element: <SiteVisiterAudience />,
  path: routes.AUDIENCES.RETARGETING.INDEX,

  children: [
    {
      element: <ListSiteVisitorAudience />,
      path: "",
    },
    {
      element: <CreateSiteVisitorAudience />,
      path: routes.AUDIENCES.ACCOUNT_AUDIENCE.CREATE,
    },
    {
      element: <CreateSiteVisitorAudience />,
      path: routes.AUDIENCES.ACCOUNT_AUDIENCE.EDIT(),
    },
  ],
};

export default SiteVisitorAudienceRoutes;
