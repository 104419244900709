import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function PublicLayout() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Container component="main" maxWidth="xs" sx={{ height: "100%" }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
