import { useTheme } from "@mui/system";
import { IconProps } from "./icon.interface";

export default function DeleteIcon({
  primaryColor,
  secondaryColor = "#fff",
  disabled = false,
}: IconProps) {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill={
          disabled
            ? theme.palette.inactive.main
            : primaryColor ?? theme.palette.negative.main
        }
      />
      <path
        d="M6.75 8.5H7.91667H17.25"
        stroke={secondaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0834 8.49999V16.6667C16.0834 16.9761 15.9605 17.2728 15.7417 17.4916C15.5229 17.7104 15.2262 17.8333 14.9167 17.8333H9.08342C8.774 17.8333 8.47725 17.7104 8.25846 17.4916C8.03966 17.2728 7.91675 16.9761 7.91675 16.6667V8.49999M9.66675 8.49999V7.33332C9.66675 7.0239 9.78967 6.72716 10.0085 6.50837C10.2273 6.28957 10.524 6.16666 10.8334 6.16666H13.1667C13.4762 6.16666 13.7729 6.28957 13.9917 6.50837C14.2105 6.72716 14.3334 7.0239 14.3334 7.33332V8.49999"
        stroke={secondaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 11.4167V14.9167"
        stroke={secondaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1667 11.4167V14.9167"
        stroke={secondaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
