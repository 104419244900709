import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function TableItemPlaceHolder() {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton variant="rounded" width={"100%"} height={40} />
    </Box>
  );
}
