import routes from "@/routes";
import { lazy } from "react";
const Attribution = lazy(() => import("@/modules/Report/Attribution"));
const ListAttribution = lazy(
  () => import("@/modules/Report/Attribution/ListAttribution")
);
const CreateOrEditAttribution = lazy(
  () => import("@/modules/Report/Attribution/CreateOrEditAttribution")
);

const AttributionRoutes = {
  element: <Attribution />,
  path: routes.REPORTS.ATTRIBUTION.INDEX,

  children: [
    {
      element: <ListAttribution />,
      path: "",
    },
    {
      element: <CreateOrEditAttribution />,
      path: routes.REPORTS.ATTRIBUTION.CREATE,
    },
    {
      element: <CreateOrEditAttribution isEdit={true} />,
      path: routes.REPORTS.ATTRIBUTION.EDIT(),
    },
  ],
};

export default AttributionRoutes;
