import { useTheme } from "@mui/material";
import { IconProps } from "./icon.interface";

export default function LoginAsIcon({
  primaryColor,
  secondaryColor = "#fff",
  disabled = false,
}: IconProps) {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 32 32"
      fill={
        disabled
          ? theme.palette.inactive.main
          : primaryColor ?? theme.palette.common.black
      }
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={32}
        height={32}
        rx={2}
        fill={
          disabled
            ? theme.palette.inactive.main
            : primaryColor ?? theme.palette.common.black
        }
      />

      <path
        d="M18.5 8.5H21.8333C22.2754 8.5 22.6993 8.67559 23.0118 8.98816C23.3244 9.30072 23.5 9.72464 23.5 10.1667V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H18.5"
        stroke={secondaryColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3335 20.1667L18.5002 16L14.3335 11.8334"
        stroke={secondaryColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 16H8.5"
        stroke={secondaryColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
