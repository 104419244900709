import CustomLoader from "@/components/Loader/CustomLoader";
import { useUserContext } from "@/context/UserContext";
import { Suspense } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import CustomDialog from "./components/Dialog/CustomDialog";
import { DialogProvider } from "./context/DialogContext";
import usePageHeadSettings from "./hooks/usePageHeadSettings";
import ErrorBoundary from "./modules/ErrorBoundary";
import { MainRoutes } from "./routes/main.routes";
import { PublicRoutes } from "./routes/public.routes";

export default function Routes() {
  const { user } = useUserContext();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [...MainRoutes(user), ...PublicRoutes],
      errorElement: <ErrorBoundary />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<CustomLoader />} />;
}
function App() {
  usePageHeadSettings();

  return (
    <div
      className="App"
      style={{ width: "100%", height: "100%", minHeight: "100vh" }}
    >
      <Suspense fallback={<CustomLoader />}>
        <DialogProvider>
          <CustomDialog />
          <Outlet />
        </DialogProvider>
      </Suspense>
    </div>
  );
}
