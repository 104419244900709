import { Theme } from "@mui/material";
import InputBase from "./inputBase";
import InputLabel from "./inputLabel";
import OutlinedInput from "./OutlinedInput";
import Select from "./select";

const InputFields = (theme: Theme) => ({
  ...InputBase(theme),
  ...InputLabel(theme),
  ...OutlinedInput(theme),
  ...Select(theme),
});

export default InputFields;
