import routes from "@/routes";
import { lazy } from "react";
const Contact = lazy(() => import("@/modules/Contact"));
const ListContact = lazy(() => import("@/modules/Contact/ListContact"));

const ContactRoutes = {
  element: <Contact />,
  path: routes.CONTACT.INDEX,

  children: [
    {
      element: <ListContact />,
      path: "",
    },
  ],
};

export default ContactRoutes;
