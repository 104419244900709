import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import * as React from "react";
import AccountAudienceRoutes from "./accountAudience.routes";
import AudienceManagerAudienceRoutes from "./audienceManager.routes";
import EmailAudienceRoutes from "./emailAudience.routes";
import SiteVisitorAudienceRoutes from "./siteVisitorAudience.routes";
const Audience = React.lazy(() => import("@/modules/Audience"));

const AudienceRoutes = (permission: IPermissions, isAdmin: boolean) => {
  const children = [];
  if (isAdmin || permission[MODULES.AUDIENCE_ACCOUNT]) {
    children.push(AccountAudienceRoutes);
  }
  if (isAdmin || permission[MODULES.AUDIENCE_EMAIL]) {
    children.push(EmailAudienceRoutes);
  }
  if (isAdmin || permission[MODULES.AUDIENCE_SITE_VISITOR]) {
    children.push(SiteVisitorAudienceRoutes);
  }
  if (isAdmin || permission[MODULES.AUDIENCE_MANAGER]) {
    children.push(AudienceManagerAudienceRoutes);
  }
  return {
    element: <Audience />,
    path: "audiences",
    children: children,
  };
};

export default AudienceRoutes;
