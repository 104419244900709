import { Svg } from "@/types";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ReactNode } from "react";

export default function NavMenu({
  list,
  anchorEl,
  open,
  handleClose,
  firstChildItem,
}: NavMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuList>
        {firstChildItem}
        {list.map(({ label, Icon, onClick }: any, i) => (
          <MenuItem key={i} onClick={onClick}>
            {Icon && (
              <ListItemIcon onClick={onClick}>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

interface NavMenuProps {
  list: Array<
    Record<string, string | string[] | (() => void) | Svg | undefined>
  >;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  firstChildItem?: ReactNode[];
}
