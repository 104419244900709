import { getCompanies, getCompanyById } from "@/apis/v1/company";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { filterDataAccordingToAllowedAdvertisers } from "../Advertiser/advertiser.validation";

const companyTypes = {
  AGENCIES: "agencies",
  ADVERTISERS: "advertisers",
  ALL: "all",
} as const;
type CompanyTypesKeys = keyof typeof companyTypes;
type CompanyType = (typeof companyTypes)[CompanyTypesKeys];
export const companiesQueryKeys = {
  default: () => ["companies"],
  withType: ({ type = "all" }: any = {}) => [
    ...companiesQueryKeys.default(),
    type,
  ],
  withId: (data: any) => [...companiesQueryKeys.withType(data), data.agencyId],
};

export function useAgenciesAndAdvertisersList(
  queryParams?: null | { type: CompanyType; text?: string; agencyId?: number },
  options?: UseQueryOptions<any, unknown, any, string[]>,
  idFilter = true,
  keyType: keyof typeof companiesQueryKeys = "withType"
) {
  return useQuery(companiesQueryKeys[keyType](queryParams), getCompanies, {
    meta: {
      queryParams: { type: "all", isPagination: false, ...queryParams },
    },
    select: idFilter
      ? filterDataAccordingToAllowedAdvertisers("id")
      : (data) => data?.rows,
    ...options,
  });
}

export function useAgencyOrAdvertiserById(meta: { id: number }) {
  return useQuery(["companies", meta.id], getCompanyById, {
    meta,
  });
}
