import CustomSwitch from "@/components/Switch/CustomSwitch";
import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from "@mui/material";
import * as React from "react";
import CardContainer from "../CardContainer";

// mui icon
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
type ExpandToggleOn = "icon" | "header" | "switch";
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardExpandable({
  title,
  Icon,
  ActionIconPrimary = null,
  ActionIconSecondary = null,
  children,
  check = false,
  disabled = false,
  setCheck,
  isChecked = false,
  expandToggleOn = "icon",
  switchLabel = true,
  customHandleCheck = undefined,
  showIcon = true,
}: CardExpandableProps) {
  const [expanded, setExpanded] = React.useState(check);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const StyledBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }));

  const expandFunction = (value: ExpandToggleOn) => {
    return expandToggleOn === value ? handleExpandClick : undefined;
  };
  return (
    <CardContainer sx={{ padding: "16px 39px" }}>
      <StyledBox
        sx={{
          justifyContent: "space-between",
          cursor: expandFunction("header") ? "pointer" : "default",
        }}
        onClick={expandFunction("header")}
      >
        <StyledBox>
          <Typography variant="bodyBigBold">{title}</Typography>
          {Icon && <Icon />}
        </StyledBox>
        <StyledBox sx={{ marginRight: "-35px" }}>
          {isChecked && (
            <CustomSwitch
              disabled={disabled}
              check={check}
              setCheck={setCheck}
              isLabel={switchLabel}
              customHandleCheck={customHandleCheck}
            />
          )}
          {ActionIconPrimary && (
            <IconButton>
              <ActionIconPrimary />
            </IconButton>
          )}
          {ActionIconSecondary && (
            <IconButton>
              <ActionIconSecondary />
            </IconButton>
          )}
          {showIcon && (
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
              onClick={
                expandToggleOn === "icon" ? expandFunction("icon") : () => null
              }
            >
              <ExpandMoreOutlinedIcon />
            </ExpandMore>
          )}
        </StyledBox>
      </StyledBox>
      <Collapse
        in={expandToggleOn === "switch" ? check : expanded}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </CardContainer>
  );
}

interface CardExpandableProps {
  Icon?: React.ElementType;
  ActionIconPrimary?: React.ElementType | null;
  ActionIconSecondary?: React.ElementType | null;
  children: React.ReactNode;
  title: string;
  check?: boolean;
  disabled?: boolean;
  setCheck?: React.Dispatch<React.SetStateAction<boolean>>;
  isChecked?: boolean;
  expandToggleOn?: ExpandToggleOn;
  switchLabel?: boolean;
  customHandleCheck?: any;
  showIcon?: boolean;
}
