import { IUserLoginResponse } from "@/modules/User/user.interface";
import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return client.post<IUserLoginResponse>("/auth/signin", {
    email,
    password,
  });
}

export function loginAs(data: QueryFunctionContext) {
  const options: any = { headers: {} };
  if (data.meta?.token) {
    options["headers"]["Authorization"] = `Bearer ${data.meta.token}`;
  }
  return client.get(`/get/user/login/${data.meta?.userId}`, options);
}

export function createUser(payload: any) {
  return client.post("/setup/user", payload);
}

export function editUser({ id, payload }: any) {
  return client.put(`/edit/permission/${id}`, payload);
}

export function getUsers({
  meta = {
    queryParams: {
      limit: 1000,
    },
  },
}: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/company/users/list`, meta?.queryParams ?? {})
  );
}

export function getCompleteProfileURL(data: QueryFunctionContext) {
  return client.get(`/retrieve/completeProfile/url/${data.meta?.userId}`);
}

export function sendUserEmail({
  userId,
  email,
}: {
  userId: string;
  email: string;
}) {
  return client.put(`/user/send-email/${userId}`, {
    email,
  });
}

export function deleteUser({ userId }: { userId: any }) {
  return client.delete(`/user/${userId}`);
}
export function getUserById(data: QueryFunctionContext) {
  return client.get(`/get/user/${data.meta?.userId}`);
}

export const completeProfileOrUpdate = async ({
  id,
  payload,
}: {
  id: string | number;
  payload: any;
}) => {
  return client.put(`/user/${id}/update`, payload);
};

export const getTokenResponse = async (data: QueryFunctionContext) => {
  return client.get(`/get/user/profile?token=${data.meta?.token}`);
};

export function updatePassword({
  id,
  payload,
}: {
  id: string | number;
  payload: any;
}) {
  return client.post(`/update/password/${id}`, payload);
}
export const forgotPassword = async (payload: any) => {
  return client.post(`/user/forget/password`, payload);
};
export const setNewPassword = async (payload: any) => {
  return client.post("/set/new/password", payload);
};
