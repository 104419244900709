import {
  GuardPermissionType,
  GUARD_PERMISSION_TYPES,
} from "@/components/Guard/PermissionGuard";
import usePermission from "./usePermission";
import { IPermissions, PermissionScope } from "@/modules/User/user.interface";
import { ITableHeaders } from "@/components/Table/table.interface";

export default function useFilterHeaders({
  headers,
  module,
  scope = ["update", "delete"],
  columns = ["action"],
}: {
  headers: ITableHeaders[];
  module: keyof IPermissions;
  scope?: GuardPermissionType[];
  columns?: string[];
}) {
  const { permission } = usePermission();
  const currentPermission = permission[module] as PermissionScope;
  const isPermitted = scope.some((permissionType) =>
    currentPermission.global.includes(GUARD_PERMISSION_TYPES[permissionType])
  );
  if (isPermitted) {
    return headers;
  } else {
    return headers.filter((header) => !columns.includes(header.id));
  }
}
