import { Typography, styled } from "@mui/material";

const CustomLabel = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "1rem",
  fontFamily: "Poppins,sans-serif",
  fontWeight: 600,
  pointerEvents: "auto",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
  display: "block",
}));

export default CustomLabel;
