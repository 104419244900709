import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const TableBody = (theme: Theme) => ({
  MuiTableBody: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontFamily: FONT_FAMILY,
      },
    },
  },
});

export default TableBody;
