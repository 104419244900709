import { checkPermission } from "@/components/Guard/PermissionGuard";
import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import routes from "@/routes";
import { lazy } from "react";
const User = lazy(() => import("@/modules/User"));
const ListUser = lazy(() => import("@/modules/User/ListUser"));
const CreateUser = lazy(() => import("@/modules/User/CreateOrEditUser/Create"));
const EditUser = lazy(() => import("@/modules/User/CreateOrEditUser/Edit"));

const UserRoutes = (permission: IPermissions, isAdmin: boolean) => {
  const children = [];
  const moduleArray = [MODULES.ADVERTISER_USERS, MODULES.AGENCY_USERS];
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "read"))
  ) {
    children.push({
      element: <ListUser />,
      path: "",
    });
  }
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "create"))
  ) {
    children.push({
      element: <CreateUser />,
      path: routes.USERS.CREATE,
    });
  }
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "update"))
  ) {
    children.push({
      element: <EditUser />,
      path: routes.USERS.EDIT(),
    });
  }

  return {
    element: <User />,
    path: routes.USERS.INDEX,
    children: children,
  };
};

export default UserRoutes;
