import { BRAND, FavSettingsArray } from "@/constants";
import getUrl from "@/utils/getUrl";
import { useLayoutEffect } from "react";

export default function usePageHeadSettings() {
  useLayoutEffect(() => {
    document.title = BRAND.title;
    FavSettingsArray.forEach((x) => {
      const linkIcon = document.querySelector(
        `link[rel=${x.rel}][sizes="${x.sizes}"]`
      );
      const linkManifest = document.querySelector("link[rel~='manifest']");
      const element = x.rel === "manifest" ? linkManifest : linkIcon;
      if (!element) {
        const link = document.createElement("link");
        link.rel = x.rel;
        if (x.type) link.type = x.type;
        if (x.sizes && x.rel.includes("icon"))
          link.setAttribute("sizes", x.sizes);

        link.href = getUrl({
          appName: BRAND.favFolderName,
          name: x.name,
          fileExtension: x.fileExtension,
        });

        document.getElementsByTagName("head")[0].appendChild(link);
      }
    });
  }, []);
}
