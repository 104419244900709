import { sendUserEmail } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import PermissionGuard from "@/components/Guard/PermissionGuard";
import DeleteIcon from "@/components/Icon/DeleteIcon";
import EditIcon from "@/components/Icon/EditIcon";
import LoginAsIcon from "@/components/Icon/LoginAsIcon";
import { StyledTableCell } from "@/components/Table/CustomTableLegacy";
import { useDialogDispatch } from "@/context/DialogContext";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { useUserContext } from "@/context/UserContext";
import useLoadingDialog from "@/hooks/useLoadingDialog";
import usePermission from "@/hooks/usePermission";
import { messages } from "@/messages";
import routes from "@/routes";
import { Box, Button, IconButton, OutlinedInput, Tooltip } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { permissionMouleByUserType } from "../data";
import {
  USERS_LIST_KEY,
  useCompleteProfileUrl,
  useDeleteUser,
  useLoginAsUser,
} from "../user.hook";

const UserTableRow = ({ row }: AdvertiserTableRowsProps) => {
  const { loggedInAdvertiserId } = useUserContext();
  const { isAdmin, isAgencyAdmin } = usePermission();
  const queryClient = useQueryClient();
  const { setSnackbarState } = useSnackbarDispatch();
  const { loginAsSomeUser, previousUserToken, user, doesPreviousUserExists } =
    useUserContext();
  const {
    refetch: getCompleteProfileUrl,
    isLoading: getCompleteProfileUrlIsLoading,
    fetchStatus: getCompleteProfileUrlFetchStatus,
  } = useCompleteProfileUrl({
    userId: row.id,
  });
  const { data: loginAsUserData, refetch } = useLoginAsUser({
    userId: row.id,
    token: previousUserToken,
  });
  const {
    mutateAsync: sendUserEmailMutate,
    isLoading: sendUserEmailIsLoading,
  } = useMutation(sendUserEmail);
  const { setDialogState, closeDialog } = useDialogDispatch();
  const navigate = useNavigate();
  function handleLoginAsClick() {
    refetch();
  }

  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();

  useLoadingDialog(isDeletingUser);

  async function handleConfirmDeleteUser() {
    const res = await deleteUser({
      userId: row.id,
    });
    queryClient.refetchQueries({
      queryKey: USERS_LIST_KEY([loggedInAdvertiserId]),
      exact: true,
    });
    setSnackbarState({
      open: true,
      message: res.message,
    });
    closeDialog();
  }

  function handleEditClick() {
    navigate(routes.USERS.EDIT(row.id), { state: row });
  }

  function handleDeleteClick() {
    setDialogState({
      open: true,
      variant: "alert",
      title: messages.alertPrompt(),
      buttonLoading: isDeletingUser,
      buttonFunction: handleConfirmDeleteUser,
      children: <>{messages.delete("user")}</>,
    });
  }

  async function handleGetCompleteProfileUrlClick() {
    const res = await getCompleteProfileUrl();
    setDialogState({
      open: true,
      title: "User Link",
      children: <UserLink value={res.data.CompleteProfileUser} />,
    });
  }

  async function handleSendUserEmailClick() {
    const res = await sendUserEmailMutate({
      userId: row.id,
      email: row.email,
    });
    setSnackbarState({
      open: true,
      message: res.message,
    });
  }

  if (loginAsUserData) {
    loginAsSomeUser({
      data: loginAsUserData,
    });
  }
  const permissionModule = permissionMouleByUserType({
    userRole: user.Role.name,
    userCompanyId: user.companyId,
    listUsercompanyId: row.companyId,
  });
  const userActionDisabled = Boolean(
    !isAdmin && (user.id === row.id || row.isSuperUser)
  );
  return (
    <>
      <StyledTableCell align="left">{row.Username}</StyledTableCell>
      <StyledTableCell align="left">{row.email}</StyledTableCell>
      <StyledTableCell align="left">{row.company.name}</StyledTableCell>
      <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
      <StyledTableCell align="left">
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <PermissionGuard module={permissionModule} permissionType="update">
            <CustomLoadingButton
              title="Send Email"
              type="submit"
              isLoading={sendUserEmailIsLoading}
              onClick={handleSendUserEmailClick}
            />
          </PermissionGuard>
          <PermissionGuard module={permissionModule} permissionType="update">
            <CustomLoadingButton
              title={row.onBoarding ? "Updated" : "Get Link"}
              type="submit"
              isLoading={Boolean(
                getCompleteProfileUrlIsLoading &&
                  getCompleteProfileUrlFetchStatus === "fetching"
              )}
              onClick={handleGetCompleteProfileUrlClick}
              disabled={row.onBoarding}
            />
          </PermissionGuard>
          {(doesPreviousUserExists || isAdmin || isAgencyAdmin) && (
            <Tooltip title="Login As">
              <IconButton
                onClick={handleLoginAsClick}
                disabled={userActionDisabled}
              >
                <LoginAsIcon disabled={userActionDisabled} />
              </IconButton>
            </Tooltip>
          )}
          <PermissionGuard module={permissionModule} permissionType="update">
            <Tooltip title="Edit">
              <IconButton
                onClick={handleEditClick}
                disabled={userActionDisabled}
              >
                <EditIcon disabled={userActionDisabled} />
              </IconButton>
            </Tooltip>
          </PermissionGuard>

          <PermissionGuard module={permissionModule} permissionType="delete">
            <Tooltip title="Delete">
              <IconButton
                onClick={handleDeleteClick}
                disabled={userActionDisabled}
              >
                {" "}
                <DeleteIcon disabled={userActionDisabled} />
              </IconButton>
            </Tooltip>
          </PermissionGuard>
        </Box>
      </StyledTableCell>
    </>
  );
};

export default UserTableRow;

interface AdvertiserTableRowsProps {
  row: any;
}

function UserLink({ value }: { value: string }) {
  const [isCopied, setIsCopied] = React.useState(false);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClick = () => {
    copyTextToClipboard(value)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Box sx={{ flex: 1 }}>
        <OutlinedInput readOnly value={value} />
      </Box>
      <Button variant="outlined" onClick={handleCopyClick}>
        {isCopied ? "Copied!" : "Copy"}
      </Button>
    </Box>
  );
}
