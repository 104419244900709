import qs from "query-string";

export default function appendQueryParams(
  url: string,
  queryParams: Record<string, any>
) {
  if (Object.keys(queryParams).length !== 0) {
    const stringifiedQueryParams = qs.stringify(queryParams, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: "comma",
    });
    return `${url}?${stringifiedQueryParams}`;
  }
  return url;
}
