import { useDialogDispatch } from "@/context/DialogContext";
import { useEffect } from "react";

export default function useLoadingDialog(isLoading: boolean) {
  const { setDialogState } = useDialogDispatch();
  useEffect(() => {
    if (!isLoading) return;
    setDialogState((current) => ({ ...current, buttonLoading: isLoading }));
  }, [isLoading]);
}
