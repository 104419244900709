import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

export const Buttons = (theme: Theme) => ({
  MuiButton: {
    variants: [
      {
        props: { variant: "custom1" },
        style: {
          display: "inline-flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          WebkitBoxPack: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          WebkitTapHighlightColor: "transparent",
          outline: "0px",
          border: "0px",
          margin: "0px",
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          appearance: "none",
          textDecoration: "none",
          fontFamily: FONT_FAMILY,
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "21px",
          letterSpacing: "0.054em",
          textTransform: "uppercase",
          minWidth: "64px",
          padding: "6px 16px",
          minHeight: "35px",
          borderRadius: "4px",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: "rgb(255, 255, 255)",
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },

          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
      },
      {
        props: { variant: "custom2" },
        style: {
          display: "inline-flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          WebkitBoxPack: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          WebkitTapHighlightColor: "transparent",
          outline: "0px",
          border: "0px",
          margin: "0px",
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          appearance: "none",
          textDecoration: "none",
          fontFamily: FONT_FAMILY,
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "21px",
          letterSpacing: "0.054em",
          textTransform: "uppercase",
          minWidth: "64px",
          padding: "6px 16px",
          minHeight: "35px",
          borderRadius: "4px",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: "rgb(255, 255, 255)",
          backgroundColor: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },

          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
      },
      {
        props: { variant: "custom3" },
        style: {
          display: "inline-flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          WebkitBoxPack: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          WebkitTapHighlightColor: "transparent",
          outline: "0px",
          border: "0px",
          margin: "0px",
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          appearance: "none",
          textDecoration: "none",
          fontFamily: FONT_FAMILY,
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "21px",
          letterSpacing: "0.054em",
          textTransform: "uppercase",
          minWidth: "64px",
          padding: "6px 16px",
          minHeight: "35px",
          borderRadius: "4px",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: theme.palette.tertiary.main,
          backgroundColor: theme.palette.tertiary.light,
          "&:hover": {
            backgroundColor: theme.palette.tertiary.light,
            filter: " brightness(97%)",
          },

          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
      },
    ],
  },
});

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    custom1: true;
    custom2: true;
    custom3: true;
  }
}
