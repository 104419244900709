import React from "react";
import ReactDOM from "react-dom/client";

// mui imports
import { CssBaseline, ThemeProvider } from "@mui/material";

// local custom imports
import Routes from "@/App";
import { UserProvider } from "@/context/UserContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CustomSnackbar from "./components/Snackbar";
import { SnackbarProvider } from "./context/SnackbarContext";
import "./index.css";
import theme from "./theme/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <UserProvider>
            <CustomSnackbar />
            <CssBaseline />
            <Routes />
          </UserProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
