import { BORDER_RADIUS, BOX_SHADOW } from "@/constants";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { CustomTabsProps } from "../tabs.interface";

export default function HorizontalTabs({
  selectedTab,
  setSelectedTab,
  tabStyles,
  variant,
  children,
}: CustomTabsProps) {
  const theme = useTheme();

  const viewMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderRadius: BORDER_RADIUS,
        overflow: "hidden",
        mb: 3,
        boxShadow: BOX_SHADOW,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="horizontal tabs"
          sx={{ ...tabStyles, width: "100%" }}
          variant={viewMobile ? "scrollable" : variant ? variant : "standard"}
        >
          {children}
        </Tabs>
      </Box>
    </Box>
  );
}
