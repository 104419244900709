import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

const CustomListForAutocomplete = React.forwardRef(
  function CustomListForAutocomplete(props: any, ref) {
    const {
      children: options,
      customProps: { handleClickIncluded, handleClickExcluded },
      ...other
    } = props;

    return (
      <List {...other} ref={ref}>
        {options.map((option: Record<string, any>, index: number) => {
          const {
            key,
            props: { onClick, ...optionProp },
            ...rest
          } = option;
          return (
            <ListItem key={index} {...optionProp}>
              <ListItemText primary={key} />
              <IconButton
                sx={{ fontSize: "22px" }}
                onClick={(e) => {
                  handleClickIncluded(
                    e,
                    optionProp["data-option-index"],
                    onClick
                  );
                }}
              >
                <CheckCircleRoundedIcon fontSize="inherit" color="secondary" />{" "}
              </IconButton>
              <IconButton
                sx={{ fontSize: "22px" }}
                onClick={(e) => {
                  handleClickExcluded(
                    e,
                    optionProp["data-option-index"],
                    onClick
                  );
                }}
              >
                <RemoveCircleRoundedIcon color="primary" fontSize="inherit" />{" "}
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
);

export default CustomListForAutocomplete;
