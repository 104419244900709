import { SnackbarProps, AlertProps } from "@mui/material";
import * as React from "react";

const SnackbarStateContext = React.createContext<ISnackbarStateContext | null>(
  null
);
const SnackbarDispatchContext =
  React.createContext<ISnackbarDispatchContext | null>(null);
export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [snackbarState, setSnackbarState] = React.useState<
    SnackbarProps & AlertProps
  >({
    open: false,
    severity: "success",
  });

  return (
    <SnackbarStateContext.Provider value={{ snackbarState }}>
      <SnackbarDispatchContext.Provider value={{ setSnackbarState }}>
        {children}
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
}

export function useSnackbarState() {
  const context = React.useContext(SnackbarStateContext);
  if (!context) {
    throw new Error(
      "useSnackbarState can only be used inside SnackbarProvider"
    );
  }
  return context;
}

export function useSnackbarDispatch() {
  const context = React.useContext(SnackbarDispatchContext);
  if (!context) {
    throw new Error(
      "useSnackbarDispatch can only be used inside SnackbarProvider"
    );
  }
  return context;
}

interface ISnackbarStateContext {
  snackbarState: SnackbarProps & AlertProps;
}
interface ISnackbarDispatchContext {
  setSnackbarState: React.Dispatch<
    React.SetStateAction<SnackbarProps & AlertProps>
  >;
}
