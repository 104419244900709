import routes from "@/routes";
import { lazy } from "react";

const ReportsCampaignSummaryAbm = lazy(
  () => import("@/modules/Report/Abm/ReportsCampaignSummaryAbm")
);
const ReportsCampaignSummaryRoutes = {
  element: <ReportsCampaignSummaryAbm />,
  path: routes.REPORTS.ABM.REPORTS,
};

export default ReportsCampaignSummaryRoutes;
