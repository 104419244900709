import CardContainer from "@/components/Card/CardContainer";
import { BORDER_RADIUS } from "@/constants";
import { Order } from "@/types";
import getComparator from "@/utils/getComparator";
import stableSort from "@/utils/stableSort";
import { TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import EnhancedTableHead from "./EnhancedTableHeader";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
    boxShadow: "none",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
  },
  // "&.MuiTableCell-root":{
  //   background: "blue"
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "25px",
  "td:first-child": {
    // borderLeftStyle: "solid",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "td:last-child": {
    // borderRightStyle: "solid",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  backgroundColor: theme.palette.common.white,
  "&:nth-of-type(odd)": {},

  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

export default function CustomTable({
  title,
  buttonName,
  ButtonIcon,
  buttonFunction,
  secondaryButtonName,
  secondaryButtonFunction,
  ButtonIconSecondary,
  showButton = true,
  headers,
  rowData = [],
  tableRowComponent: TableRowComponent,
  buttonVariant = "custom1",
  pagination = true,
  isCheck = false,
  handleDelete,
  page = 0,
  handleChangePage = () => null,
  rowsPerPage = 5,
  handleChangeRowsPerPage = () => null,
  totalCount,
  tableRowComponentProps,
  isAsync = true,
  handleSortReq = () => null,
}: CustomTableProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState(headers[0]);
  const [selected, setSelected] = React.useState<Array<any>>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSortReq(property, isAsc ? "A" : "D");
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClickCheckBox = (
    event: React.MouseEvent<unknown>,
    name: string | number
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<any> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onCheckTableRowProps: any = isCheck
    ? {
        handleClickCheckBox,
        selected,
      }
    : null;
  return (
    <Box sx={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}>
      <CardContainer
        sx={{
          padding: "2rem",
          display: "flex",
          maxWidth: "100%",
          width: "100%",
        }}
      >
        {(title || buttonName || isCheck) && (
          <EnhancedTableToolbar
            buttonName={buttonName}
            ButtonIcon={ButtonIcon}
            buttonFunction={buttonFunction}
            secondaryButtonName={secondaryButtonName}
            ButtonIconSecondary={ButtonIconSecondary}
            secondaryButtonFunction={secondaryButtonFunction}
            showButton={showButton}
            buttonVariant={buttonVariant}
            title={title}
            numSelected={selected.length}
            handleDelete={handleDelete}
            selected={selected}
          />
        )}
        <Box sx={{ overflow: "auto" }}>
          <Table
            sx={{
              minWidth: "800px",
              maxWidth: "100%",
              overflow: "auto",
              "-webkit-border-horizontal-spacing": "0",
              "-webkit-border-vertical-spacing": "1em",
              "border-collapse": "separate",
            }}
            aria-label="customized table"
          >
            <EnhancedTableHead
              headCells={headers}
              orderBy={orderBy}
              order={order}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rowData?.length}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              isCheck={isCheck}
            />
            <TableBody sx={{ borderRadius: BORDER_RADIUS }}>
              {stableSort(rowData, getComparator(order, orderBy)).map(
                (row, index) => {
                  const rowKey = row?.id ?? index;
                  return (
                    <StyledTableRow key={rowKey}>
                      <TableRowComponent
                        row={row}
                        {...onCheckTableRowProps}
                        {...tableRowComponentProps}
                      />
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={isAsync ? -1 : totalCount ? totalCount : rowData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </CardContainer>
    </Box>
  );
}
export interface CustomTableButtonProps {
  showButton?: boolean;
  buttonName?: string;
  ButtonIcon?: any;
  buttonFunction?: () => void;
  secondaryButtonName?: string;
  ButtonIconSecondary?: any;
  secondaryButtonFunction?: () => void;
  buttonVariant?: "text" | "custom1" | "outlined" | "contained" | undefined;
}
interface CustomTableProps extends CustomTableButtonProps {
  title?: string;
  headers: Array<string>;
  rowData: Array<Record<string, string | number | string[]>>;
  tableRowComponent?: any;
  pagination?: boolean;
  isCheck?: boolean;
  handleDelete?: any;
  tableRowComponentProps?: any;
  page?: number;
  handleChangePage?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  rowsPerPage?: number;
  totalCount?: number;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  isAsync?: boolean;
  handleSortReq?: (property: string, orderBy: string) => void;
}
