import { Order } from "@/types";

export default function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (typeof orderBy === "string") {
    const split = orderBy.split(".");
    if (split.length > 1) {
      split.forEach((key) => {
        a = a[key as keyof typeof a] as T;
        b = b[key as keyof typeof b] as T;
      });
    } else {
      a = a[orderBy] as T;
      b = b[orderBy] as T;
    }
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
