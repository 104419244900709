// local custom imports
import { FONT_FAMILY } from "@/constants";
import { FontSizeFunction } from "@/theme/typography/typography.interface";
import { Palette } from "@mui/material";

export const Headings = (
  ResponsiveFontSizes: FontSizeFunction,
  palette: Palette
) => {
  return {
    h1Reg: {
      fontFamily: FONT_FAMILY,
      lineHeight: "123.5%",
      letterSpacing: "-0.02em",
      fontWeight: 400,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 34, sm: 36, md: 38, lg: 40, xl: 40 }),
    },
    h1Semi: {
      fontFamily: FONT_FAMILY,
      lineHeight: "123.5%",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 34, sm: 36, md: 38, lg: 40, xl: 40 }),
    },
    h1Bold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "123.5%",
      letterSpacing: "-0.02em",
      fontWeight: 700,
      color: palette.text.primary,
      "text-transform": "uppercase",
      ...ResponsiveFontSizes({ xs: 34, sm: 36, md: 38, lg: 40, xl: 40 }),
    },
    h2Reg: {
      fontFamily: FONT_FAMILY,
      lineHeight: "51px",
      letterSpacing: "-0.02em",
      fontWeight: 400,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 28, sm: 30, md: 32, lg: 34, xl: 34 }),
    },
    h2Semi: {
      fontFamily: FONT_FAMILY,
      lineHeight: "51px",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 28, sm: 30, md: 32, lg: 34, xl: 34 }),
    },
    h2Bold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "51px",
      letterSpacing: "-0.02em",
      fontWeight: 700,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 28, sm: 30, md: 32, lg: 34, xl: 34 }),
    },
    h3Reg: {
      fontFamily: FONT_FAMILY,
      lineHeight: "36px",
      letterSpacing: "-0.01em",
      fontWeight: 400,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 18, sm: 20, md: 22, lg: 24, xl: 24 }),
    },
    h3Semi: {
      fontFamily: FONT_FAMILY,
      lineHeight: "36px",
      letterSpacing: "-0.01em",
      fontWeight: 600,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 18, sm: 20, md: 22, lg: 24, xl: 24 }),
    },
    h3Bold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "36px",
      letterSpacing: "-0.01em",
      fontWeight: 700,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 18, sm: 20, md: 22, lg: 24, xl: 24 }),
    },
    h4Reg: {
      fontFamily: FONT_FAMILY,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      fontWeight: 400,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 20, xl: 20 }),
    },
    h4Semi: {
      fontFamily: FONT_FAMILY,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      fontWeight: 600,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 20, xl: 20 }),
    },
    h4Bold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      fontWeight: 700,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 20, xl: 20 }),
    },
    h5Reg: {
      fontFamily: FONT_FAMILY,
      lineHeight: "27px",
      letterSpacing: "-0.01em",
      fontWeight: 400,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 14, md: 16, lg: 18, xl: 18 }),
    },
    h5Semi: {
      fontFamily: FONT_FAMILY,
      lineHeight: "27px",
      letterSpacing: "-0.01em",
      fontWeight: 600,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 14, md: 16, lg: 18, xl: 18 }),
    },
    h5Bold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "27px",
      letterSpacing: "-0.01em",
      fontWeight: 700,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 14, md: 16, lg: 18, xl: 18 }),
    },
  };
};
