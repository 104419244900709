import CustomCheckBox from "@/components/Form/CustomCheckBox";
import { TName } from "@/types";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { isRenderComponent } from "../data";
import {
  CommonFieldComponentProps,
  ComponentData,
  NodeComponentProps,
} from "../user.interface";

export default function CheckBoxModule(props: CommonFieldComponentProps) {
  const { componentData, permissions, createUserType, isEdit = false } = props;
  return (
    <Grid item xs={12}>
      {" "}
      {componentData.map((x: ComponentData, i: number) => {
        if (
          isRenderComponent({ data: x, permissions, createUserType, isEdit })
        ) {
          return <Component key={i} {...props} data={x} />;
        }
      })}
    </Grid>
  );
}

function Component({ control, errors, data, isEdit }: NodeComponentProps) {
  const watched = useWatch({
    name: data.dependent as TName<any>,
    control,
    disabled: !data.dependent,
  });

  return (
    <CustomCheckBox
      label={data.text}
      required={data.required}
      name={data.name}
      control={control}
      error={errors?.[data.name]?.message}
      checkBoxSize="small"
      disabled={data?.dependentCondition?.(watched)}
    />
  );
}
