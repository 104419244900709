import { Theme } from "@mui/material";

const DialogContent = (theme: Theme) => ({
  MuiDialogContent: {
    styleOverrides: {
      // Name of the slot
      root: {
        paddingTop: 0,
      },
    },
  },
});

export default DialogContent;
