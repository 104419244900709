export default function DummyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="inherit"
      height="inherit"
    >
      <path d="M 25.494141 4.9980469 C 24.185141 4.9980469 22.89 5.3478594 21.75 6.0058594 C 20.015 7.0078594 18.773859 8.6235937 18.255859 10.558594 C 17.736859 12.493594 18.002047 14.514281 18.998047 16.238281 L 33.005859 41.25 C 34.341859 43.563 36.831859 45.001953 39.505859 45.001953 C 40.814859 45.001953 42.11 44.652141 43.25 43.994141 C 44.985 42.992141 46.226141 41.376406 46.744141 39.441406 C 47.263141 37.506406 46.997953 35.485719 46.001953 33.761719 L 31.994141 8.75 C 30.658141 6.437 28.168141 4.9980469 25.494141 4.9980469 z M 16.070312 13.640625 L 7.0703125 28.640625 C 8.1403125 28.230625 9.29 28 10.5 28 C 15.35 28 19.369688 31.659375 19.929688 36.359375 L 24.089844 29.419922 L 17.269531 17.240234 C 16.619531 16.120234 16.220312 14.900625 16.070312 13.640625 z M 10.5 30 A 7.5 7.5 0 0 0 10.5 45 A 7.5 7.5 0 0 0 10.5 30 z" />
    </svg>
  );
}
