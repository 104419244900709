import { lazy } from "react";
const Integration = lazy(() => import("@/modules/integration"));
const ListIntegration = lazy(
  () => import("@/modules/integration/ListIntegration")
);

const IntegrationRoutes = {
  element: <Integration />,
  path: "integrations",
  children: [
    {
      element: <ListIntegration />,
      path: "",
    },
  ],
};
export default IntegrationRoutes;
