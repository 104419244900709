import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import PermissionGuard from "@/components/Guard/PermissionGuard";
import CustomSearch from "@/components/Search/CustomSearch";
import {
  ITableButton,
  SearchField,
  SelectedAction,
} from "@/components/Table/table.interface";
import { Box, Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    title,
    buttonName,
    ButtonIcon,
    ButtonIconSecondary,
    buttonFunction,
    buttonDisabled = false,
    buttonLoading = false,
    secondaryButtonName,
    secondaryButtonFunction,
    showButton = true,
    buttonVariant,
    handleDelete = () => null,
    selected,
    isHeader,
    isCheck,
    rowCount,
    onSelectAllClick,
    isAllSelected,
    selectedActionObject,
    primaryButtonPermission,
    searchField,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        justifyContent: "space-between",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {!isHeader && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!isHeader && isCheck && (
                  <Checkbox
                    color="primary"
                    checked={rowCount > 0 && isAllSelected}
                    onChange={onSelectAllClick}
                  />
                )}
                <Typography variant="bodyBigBold">
                  {isAllSelected ? "Unselect All" : "Select All"}
                </Typography>
              </Box>
            )}
            {numSelected} selected
          </Box>
        </Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isHeader && isCheck && (
            <Checkbox
              color="primary"
              checked={rowCount > 0 && isAllSelected}
              onChange={onSelectAllClick}
            />
          )}
          <Typography variant="bodyBigBold">{title}</Typography>
        </Box>
      )}
      {numSelected > 0 ? (
        <>
          {selectedActionObject?.map((x, i) => {
            const Icon = x.icon;
            return (
              <Tooltip key={i} title={x.type}>
                <IconButton onClick={x.handleOnClick}>
                  <Icon />
                </IconButton>
              </Tooltip>
            );
          })}
        </>
      ) : (
        <>
          {" "}
          {searchField?.setSearch && (
            <CustomSearch
              type="secondary"
              placeholder="Search"
              styles={{ maxWidth: "250px" }}
              {...searchField}
            />
          )}
          <>
            {" "}
            {showButton && (
              <div style={{ display: "flex", gap: "10px" }}>
                {primaryButtonPermission?.module ? (
                  <PermissionGuard {...primaryButtonPermission}>
                    <CustomLoadingButton
                      variant={buttonVariant}
                      startIcon={ButtonIcon && <ButtonIcon />}
                      onClick={buttonFunction}
                      disabled={buttonDisabled}
                      isLoading={buttonLoading}
                      title={buttonName ?? ""}
                    />
                  </PermissionGuard>
                ) : (
                  <CustomLoadingButton
                    variant={buttonVariant}
                    startIcon={ButtonIcon && <ButtonIcon />}
                    onClick={buttonFunction}
                    disabled={buttonDisabled}
                    isLoading={buttonLoading}
                    title={buttonName ?? ""}
                  />
                )}
                {secondaryButtonName && (
                  <Button
                    variant={buttonVariant}
                    startIcon={ButtonIconSecondary && <ButtonIconSecondary />}
                    onClick={secondaryButtonFunction}
                  >
                    {secondaryButtonName}
                  </Button>
                )}
              </div>
            )}
          </>
        </>
      )}
    </Toolbar>
  );
}

interface EnhancedTableToolbarProps extends ITableButton {
  numSelected: number;
  title?: string;
  handleDelete?: any;
  selected?: Array<any>;
  isHeader: boolean;
  isCheck: boolean;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAllSelected: boolean;
  selectedActionObject?: SelectedAction[];
  searchField?: SearchField;
}
