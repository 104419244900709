// local custom imports
import { FONT_FAMILY } from "@/constants";
import { FontSizeFunction } from "@/theme/typography/typography.interface";
import { Palette } from "@mui/material";

export const Others = (
  ResponsiveFontSizes: FontSizeFunction,
  palette: Palette
) => {
  return {
    errorBold: {
      fontFamily: FONT_FAMILY,
      lineHeight: "123.5%",
      letterSpacing: "-0.02em",
      fontWeight: 700,
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 32, sm: 48, md: 64, lg: 64, xl: 64 }),
    },
    buttonReg: {
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0.75px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
      "text-transform": "uppercase",
    },
    buttonSemi: {
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "0.75px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
      "text-transform": "uppercase",
    },
    buttonBold: {
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "21px",
      letterSpacing: "0.75px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
      "text-transform": "uppercase",
    },
    subtitle2Reg: {
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
    subtitle2Semi: {
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
    subtitle2Bold: {
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "22px",
      letterSpacing: "0.1px",
      ...ResponsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
    captionReg: {
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
    captionSemi: {
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
    captionBold: {
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
    overlineReg: {
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "text-transform": "uppercase",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
    overlineSemi: {
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "text-transform": "uppercase",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
    overlineBold: {
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "text-transform": "uppercase",
      color: palette.text.primary,
      ...ResponsiveFontSizes({ xs: 10, sm: 11, md: 12, lg: 12, xl: 12 }),
    },
  };
};
