import { Theme } from "@mui/material/styles";
import InputFields from "./inputField";
import { Buttons, IconButtons } from "./button";
import { Drawer } from "./drawer";
import Table from "./table";
import Dialog from "./dialog";

const OverridesComponents = (theme: Theme) => ({
  ...InputFields(theme),
  ...Buttons(theme),
  ...IconButtons(theme),
  ...Drawer(theme),
  ...Table(theme),
  ...Dialog(theme),
});

export default OverridesComponents;
