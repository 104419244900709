import Setup from "@/modules/Setup";
import AgencyRoutes from "./agency.routes";
import UserRoutes from "./user.routes";

const SetupRoutes = {
  element: <Setup />,
  path: "setup",
  children: [UserRoutes({}, true), AgencyRoutes],
};

export default SetupRoutes;
