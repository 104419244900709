import { Box, Tooltip } from "@mui/material";
import { cloneElement, forwardRef, memo } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

export default function WatchedInputWrapper({
  name,
  control,
  valueToWatch,
  secondValueToWatch = "",
  secondValueFunction,
  isValueExpression,
  children,
  getValues,
  setValue,
  alwaysRender = false,
  disabledField,
  resetField,
  isRequired = false,
  toolTipTitleForDisabled,
  toolTipTitle,
}: WatchedInputWrapperProps) {
  const watchValue = useWatch({
    control,
    name: valueToWatch,
    disabled: Boolean(!valueToWatch),
  });
  const secondWatchValue = useWatch({
    control,
    name: secondValueToWatch,
    disabled: Boolean(!secondValueToWatch),
  });

  const isValue = valueToWatch ? isValueExpression?.(watchValue) : true;

  const isRender = alwaysRender ? alwaysRender : isValue;
  if (!isValue) {
    const val = getValues?.(name);
    if (val) resetField ? resetField(name) : setValue?.(name, null);
  }

  const dyanmicProps = secondValueToWatch
    ? secondValueFunction?.(secondWatchValue)
    : {};

  if (disabledField && dyanmicProps) {
    if (!dyanmicProps.disabled) {
      dyanmicProps["disabled"] = !isValue;
    }
  }
  if (isRequired && dyanmicProps)
    dyanmicProps["required"] = isValueExpression?.(watchValue);

  return (
    <Tooltip
      title={
        toolTipTitleForDisabled
          ? !isValue
            ? toolTipTitleForDisabled
            : toolTipTitle
            ? toolTipTitle
            : undefined
          : toolTipTitle
          ? toolTipTitle
          : undefined
      }
      placement="top"
    >
      <ChildComponent isRender={isRender} dyanmicProps={dyanmicProps}>
        {children}
      </ChildComponent>
    </Tooltip>
  );
}

interface WatchedInputWrapperProps {
  name: string;
  control: Control<any>;
  valueToWatch: string;
  secondValueToWatch?: string;
  secondValueFunction?: any;
  isValueExpression?: (arg: any) => boolean;
  children: React.ReactElement;
  getValues?: UseFormGetValues<any> | null;
  setValue?: UseFormSetValue<any> | null;
  alwaysRender?: boolean;
  disabledField?: boolean;
  resetField?: UseFormResetField<any>;
  isRequired?: boolean;
  toolTipTitleForDisabled?: string;
  toolTipTitle?: string;
}

const ChildComponent = forwardRef(function ChildComponent(props: any, ref) {
  const { isRender, children, dyanmicProps, ...rest } = props;

  return (
    <Box ref={ref} {...rest}>
      <LeafComponent isRender={isRender} dyanmicProps={dyanmicProps}>
        {children}
      </LeafComponent>
    </Box>
  );
});

const LeafComponent = memo(function LeafComponent({
  isRender,
  children,
  dyanmicProps,
}: LeafComponent) {
  return <Box>{isRender && cloneElement(children, dyanmicProps)}</Box>;
});

interface ChildComponentProps {
  isRender: boolean;
  children: React.ReactElement;
  dyanmicProps: any;
}

type LeafComponent = ChildComponentProps;
