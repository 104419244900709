import { formFieldError } from "@/types";
import labelRequired from "@/utils/labelRequired";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useController, UseControllerProps } from "react-hook-form";

export default function CustomCheckBox({
  name,
  control,
  label = null,
  required,
  error,
  checkBoxSize = "medium",
  disabled = false,
}: CustomAutocompleteProps) {
  const { field, fieldState } = useController({ control, name });
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox checked={field?.value} {...field} size={checkBoxSize} />
        }
        label={labelRequired(label, required)}
        disabled={disabled}
      />
      <Box sx={{ mx: "14px" }}>
        {error && <FormHelperText error>{`${error}`}</FormHelperText>}
      </Box>
    </Box>
  );
}

interface CustomAutocompleteProps extends UseControllerProps {
  label?: string | null;
  required?: boolean;
  error?: formFieldError;
  checkBoxSize?: "medium" | "small";
  disabled?: boolean;
}
