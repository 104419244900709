import {
  FORM_COLUMN_SPACING,
  FORM_ROW_SPACING,
  LAYOUT_PRIMARY_COLUMN_SPACING,
  LAYOUT_PRIMARY_ROW_SPACING,
} from "@/constants";
import Grid, { GridProps } from "@mui/material/Grid";
import { ReactNode } from "react";

export default function SpacingGrid({
  spacingType,
  children,
  item = false,
  itemContainer = false,
  ...rest
}: SpacingGridProps) {
  const spacing =
    spacingType === "form"
      ? {
          columnSpacing: FORM_COLUMN_SPACING,
          rowSpacing: FORM_ROW_SPACING,
        }
      : spacingType === "layout"
      ? {
          columnSpacing: LAYOUT_PRIMARY_COLUMN_SPACING,
          rowSpacing: LAYOUT_PRIMARY_ROW_SPACING,
        }
      : {};
  const behavior = item
    ? {
        item: true,
        xs: 12,
      }
    : itemContainer
    ? {
        item: true,
        xs: 12,
        container: true,
      }
    : {
        container: true,
      };
  const props = {
    justifyContent: "center",
    ...behavior,
    ...spacing,
    ...rest,
  };
  return <Grid {...props}>{children}</Grid>;
}

interface SpacingGridProps extends Omit<GridProps, "item" | "container"> {
  spacingType?: "form" | "layout";
  children: ReactNode;
  item?: boolean;
  itemContainer?: boolean;
}
