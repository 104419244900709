import { Svg } from "@/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, TooltipProps } from "@mui/material";

export default function CustomIconTooltip({
  placement = "top",
  title,
  icon: Icon,
  iconSize = "medium",
  ...rest
}: CustomIconTooltipProps) {
  return (
    <Tooltip placement={placement} title={title} {...rest}>
      {Icon ? (
        <Icon fontSize={iconSize} />
      ) : (
        <InfoOutlinedIcon fontSize={iconSize} />
      )}
    </Tooltip>
  );
}

export interface CustomIconTooltipProps extends Omit<TooltipProps, "children"> {
  icon?: Svg;
  iconSize?: "medium" | "small";
}
