import { SxProps, Tab } from "@mui/material";
import { Link } from "react-router-dom";

export default function LinkTab({
  link,
  label,
  disabled = false,
  a11yProps,
  styles = {},
  ...rest
}: LinkTabProps) {
  return (
    <Tab
      component={Link}
      to={link}
      label={label}
      disabled={disabled}
      {...a11yProps}
      sx={{ ...styles }}
      {...rest}
    />
  );
}

interface LinkTabProps {
  link: string;
  label: string;
  disabled?: boolean;
  a11yProps?: Record<string, string>;
  styles?: SxProps;
}
