import routes from "@/routes";
import { lazy } from "react";
const Linkedin = lazy(() => import("@/modules/Asset/Creative/Linkedin"));
const LinkedinListAsset = lazy(
  () => import("@/modules/Asset/Creative/Linkedin/List")
);
const CreateOrEditAsset = lazy(
  () => import("@/modules/Asset/Creative/CreateOrEditAsset")
);
const DisplayDetailAsset = lazy(
  () => import("@/modules/Asset/Creative/DetailAsset/index")
);
const AssetRoutes = {
  element: <Linkedin />,
  path: routes.ASSETS.CREATIVES.LINKEDIN.INDEX,

  children: [
    {
      element: <LinkedinListAsset />,
      path: "",
    },
    {
      element: <DisplayDetailAsset isLinkedin={true} />,
      path: routes.ASSETS.CREATIVES.DETAIL(),
    },
    {
      element: <CreateOrEditAsset isLinkedin={true} />,
      path: routes.ASSETS.CREATIVES.CREATE,
    },
    {
      element: <CreateOrEditAsset isLinkedin={true} isEdit={true} />,
      path: routes.ASSETS.CREATIVES.EDIT(),
    },
  ],
};

export default AssetRoutes;
