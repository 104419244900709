// mui imports
import type {
  Breakpoint,
  Theme,
  TypographyVariantsOptions,
} from "@mui/material";

// local custom imports
import { Headings } from "./variants/headings";
import { Bodys } from "./variants/bodys";
import { Others } from "./variants/others";
import { FONT_FAMILY } from "@/constants";

const Typography = (theme: Theme): TypographyVariantsOptions => {
  const {
    typography: { pxToRem },
    palette,
  } = theme;

  function ResponsiveFontSizes({
    xs,
    sm,
    md,
    lg,
    xl,
  }: Record<Breakpoint, number>) {
    return {
      fontSize: pxToRem(xl),
      "@media (max-width:1535px)": {
        fontSize: pxToRem(lg),
      },
      "@media (max-width:1199px)": {
        fontSize: pxToRem(md),
      },
      "@media (max-width:899px)": {
        fontSize: pxToRem(sm),
      },
      "@media (max-width: 599px)": {
        fontSize: pxToRem(xs),
      },
    };
  }

  return {
    allVariants: {
      fontFamily: FONT_FAMILY,
    },
    fontWeightMedium: 600,
    ...Headings(ResponsiveFontSizes, palette),
    ...Bodys(ResponsiveFontSizes, palette),
    ...Others(ResponsiveFontSizes, palette),
  };
};

export default Typography;

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h1Reg: React.CSSProperties;
    h2Reg: React.CSSProperties;
    h3Reg: React.CSSProperties;
    h4Reg: React.CSSProperties;
    h5Reg: React.CSSProperties;
    bodyBigReg: React.CSSProperties;
    body1Reg: React.CSSProperties;
    body2Reg: React.CSSProperties;
    subtitleReg: React.CSSProperties;
    buttonReg: React.CSSProperties;
    captionReg: React.CSSProperties;
    h1Semi: React.CSSProperties;
    h2Semi: React.CSSProperties;
    h3Semi: React.CSSProperties;
    h4Semi: React.CSSProperties;
    h5Semi: React.CSSProperties;
    bodyBigSemi: React.CSSProperties;
    body1Semi: React.CSSProperties;
    body2Semi: React.CSSProperties;
    subtitleSemi: React.CSSProperties;
    buttonSemi: React.CSSProperties;
    captionSemi: React.CSSProperties;
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    bodyBigBold: React.CSSProperties;
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
    subtitleBold: React.CSSProperties;
    buttonBold: React.CSSProperties;
    captionBold: React.CSSProperties;
    errorBold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Reg?: React.CSSProperties;
    h2Reg?: React.CSSProperties;
    h3Reg?: React.CSSProperties;
    h4Reg?: React.CSSProperties;
    h5Reg?: React.CSSProperties;
    bodyBigReg?: React.CSSProperties;
    body1Reg?: React.CSSProperties;
    body2Reg?: React.CSSProperties;
    subtitleReg?: React.CSSProperties;
    buttonReg?: React.CSSProperties;
    captionReg?: React.CSSProperties;
    overlineReg?: React.CSSProperties;
    h1Semi?: React.CSSProperties;
    h2Semi?: React.CSSProperties;
    h3Semi?: React.CSSProperties;
    h4Semi?: React.CSSProperties;
    h5Semi?: React.CSSProperties;
    bodyBigSemi?: React.CSSProperties;
    body1Semi?: React.CSSProperties;
    body2Semi?: React.CSSProperties;
    subtitleSemi?: React.CSSProperties;
    buttonSemi?: React.CSSProperties;
    captionSemi?: React.CSSProperties;
    overlineSemi?: React.CSSProperties;
    h1Bold?: React.CSSProperties;
    h2Bold?: React.CSSProperties;
    h3Bold?: React.CSSProperties;
    h4Bold?: React.CSSProperties;
    h5Bold?: React.CSSProperties;
    bodyBigBold?: React.CSSProperties;
    body1Bold?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
    subtitleBold?: React.CSSProperties;
    buttonBold?: React.CSSProperties;
    captionBold?: React.CSSProperties;
    overlineBold?: React.CSSProperties;
    errorBold?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1Reg?: true;
    h2Reg?: true;
    h3Reg?: true;
    h4Reg?: true;
    h5Reg?: true;
    bodyBigReg?: true;
    body1Reg?: true;
    body2Reg?: true;
    subtitleReg?: true;
    buttonReg?: true;
    captionReg?: true;
    overlineReg?: true;
    h1Semi?: true;
    h2Semi?: true;
    h3Semi?: true;
    h4Semi?: true;
    h5Semi?: true;
    bodyBigSemi?: true;
    body1Semi?: true;
    body2Semi?: true;
    subtitleSemi?: true;
    buttonSemi?: true;
    captionSemi?: true;
    overlineSemi?: true;
    h1Bold?: true;
    h2Bold?: true;
    h3Bold?: true;
    h4Bold?: true;
    h5Bold?: true;
    bodyBigBold?: true;
    body1Bold?: true;
    body2Bold?: true;
    subtitleBold?: true;
    buttonBold?: true;
    captionBold?: true;
    overlineBold?: true;
    errorBold?: true;
  }
}
