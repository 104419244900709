import { getCompanies, getCompanyById } from "@/apis/v1/company";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { AdvertiserSwitchState } from "./advertiser.interface";
import { pick } from "lodash";
import { filterDataAccordingToAllowedAdvertisers } from "./advertiser.validation";

export const ADVERTISERS_LIST_KEY = (page?: number, size?: number) => [
  "advertisers",
  page,
  size,
];

type useAdvertiserListArgs = {
  onSuccessFunction?: (data: any) => void;
  queryParams?: {
    agencyId?: number;
  };
};
type usePaginatedAdvertiserListArgs = {
  onSuccessFunction?: (data: any) => void;
  queryParams?: {
    agencyId?: number;
    page?: number;
    size?: number;
  };
};

export function useAdvertisersList({
  onSuccessFunction,
  queryParams,
}: useAdvertiserListArgs = {}) {
  return useQuery(ADVERTISERS_LIST_KEY(), getCompanies, {
    meta: {
      queryParams: {
        type: "advertisers",
        ...queryParams,
        isPagination: false,
      },
    },
    select: (data) => filterDataAccordingToAllowedAdvertisers("id")(data?.rows),
    onSuccess: (data) => onSuccessFunction?.(data),
  });
}
export function usePaginatedAdvertisersList({
  onSuccessFunction,
  queryParams,
}: usePaginatedAdvertiserListArgs = {}) {
  return useQuery(
    ADVERTISERS_LIST_KEY(queryParams?.page || 0, queryParams?.size || 10),
    getCompanies,
    {
      meta: {
        queryParams: {
          type: "advertisers",
          ...queryParams,
          isPagination: true,
        },
      },
      select: filterDataAccordingToAllowedAdvertisers("id"),
      onSuccess: (data) => onSuccessFunction?.(data),
    }
  );
}
export function useAdvertiser(id?: string | number) {
  return useQuery(["advertiser", id], getCompanyById, {
    meta: {
      id,
    },
    enabled: Boolean(id),
  });
}

export function useSwitchState(state?: AdvertiserSwitchState) {
  const [switchState, setSwitchState] = useState({
    display: state?.display ?? false,
    linkedin: state?.linkedin ?? false,
  });

  return { switchState, setSwitchState };
}

export function generatePayload({
  activeState,
  data,
  adAccounts,
}: {
  activeState: AdvertiserSwitchState;
  data: any;
  adAccounts: any;
  advertiserId?: any;
}) {
  let payload: Record<string, string | boolean> = {
    name: data.name,
    canConnectLinkedin: Boolean(data.canConnectLinkedin),
  };

  if (activeState.linkedin) {
    if (!data.canConnectLinkedin) {
      if (data.linkedinAdAccountId) {
        const adAccount = adAccounts?.elements?.find(
          (item: any) => item.id === data.linkedinAdAccountId
        );
        data["linkedinPageId"] = adAccount.reference.split(
          "urn:li:organization:"
        )[1];
        payload = {
          ...payload,
          ...pick(data, [
            "linkedinPageId",
            "linkedinCurrency",
            "linkedinAdAccountId",
          ]),
        };
      } else {
        payload = {
          ...payload,
          ...pick(data, ["linkedinPageId", "linkedinCurrency"]),
        };
      }
    }
  }

  if (activeState.display) {
    payload = {
      ...payload,
      ...pick(data, ["domain"]),
    };
  }

  return payload;
}

export function generateParams({
  activeState,
  data,
}: {
  activeState: AdvertiserSwitchState;
  data: any;
}) {
  if (activeState.linkedin) {
    return { linkedinAccountType: data.linkedinAccountType };
  }

  return {};
}
