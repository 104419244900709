import { addRequestInterceptors } from "@/apis/client";
import { getFirstTabPath, permittedTabs } from "@/components/Drawer/data";
import { PREVIOUS_USER_KEY, TOKEN_KEY, USER_KEY, USER_TYPE } from "@/constants";
import { IAdvertiser } from "@/modules/Advertiser/advertiser.interface";
import { mapPermission } from "@/modules/User/data";
import { IUser, IUserLoginResponse } from "@/modules/User/user.interface";
import routes from "@/routes";
import _ from "lodash";
import * as React from "react";

const UserContext = React.createContext<IUserContext | null>(null);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const stringifiedUser = window.localStorage.getItem(USER_KEY);
  const stringifiedPreviousUser =
    window.localStorage.getItem(PREVIOUS_USER_KEY);
  const previousUser = stringifiedPreviousUser
    ? JSON.parse(stringifiedPreviousUser)
    : null;

  appendAdvertiserIdParamInRequest(stringifiedUser);
  const [token, setToken] = React.useState<string>(
    window.localStorage.getItem(TOKEN_KEY) ?? ""
  );
  const [user, setUser] = React.useState<null | IUser>(
    stringifiedUser ? JSON.parse(stringifiedUser) : null
  );
  const doesPreviousUserExists = Boolean(
    previousUser?.user && previousUser?.token
  );

  function logout() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    setUser(null);
    window.location.href = routes.LOGIN;
  }
  function switchBackToPreviousUser() {
    if (user?.asAdvertiser) {
      window.localStorage.setItem(
        USER_KEY,
        JSON.stringify({
          ..._.omit(user, ["asAdvertiser"]),
          Role: {
            ..._.omit(user.Role, ["$oldCompanyPermissions"]),
            $companyPermissions: user.Role.$companyPermissions,
          },
        })
      );
      window.location.href = routes.DASHBOARD + "/" + routes.ADVERTISERS.INDEX;
    } else if (doesPreviousUserExists) {
      window.localStorage.setItem(TOKEN_KEY, previousUser.token);
      window.localStorage.setItem(USER_KEY, JSON.stringify(previousUser.user));
      window.localStorage.removeItem(PREVIOUS_USER_KEY);
      window.location.href = routes.DASHBOARD;
    } else {
      return;
    }
    setUser(null);
  }

  function loginAsSomeUser({ data }: { data: IUserLoginResponse }) {
    if (!doesPreviousUserExists) {
      window.localStorage.setItem(
        PREVIOUS_USER_KEY,
        JSON.stringify({
          token,
          user,
        })
      );
    }
    const userObj = {
      ...data.user,
    };

    window.localStorage.setItem(USER_KEY, JSON.stringify(userObj));
    window.localStorage.setItem(TOKEN_KEY, data.token);

    const tabs = permittedTabs(
      data.user.Role.$permissions,
      data.user.Role.name === USER_TYPE.SUPER_ADMIN
    );
    if (tabs) {
      window.location.href =
        window.location.origin + routes.DASHBOARD + "/" + getFirstTabPath(tabs);
    } else window.location.href = window.location.origin + routes.DASHBOARD;
  }

  // Any agency user can login as an advertiser ( The Company )
  // User will be shown an advertiser's users view, but the user
  // account is still that of an agency's user
  function loginAsAdvertiser(advertiser: IAdvertiser) {
    const $companyPermissions = mapPermission(
      advertiser.role.rolepermissions,
      true
    );

    window.localStorage.setItem(
      USER_KEY,
      JSON.stringify({
        ...user,
        asAdvertiser: advertiser.id,
        Role: {
          ...user?.Role,
          $companyPermissions,
          $oldCompanyPermissions: user?.Role.$companyPermissions,
        },
      })
    );

    const tabs =
      user &&
      permittedTabs(
        user.Role.$companyPermissions,
        user.Role.name === USER_TYPE.SUPER_ADMIN
      );
    if (tabs) {
      window.location.href =
        window.location.origin + routes.DASHBOARD + "/" + getFirstTabPath(tabs);
    } else window.location.href = window.location.origin + routes.DASHBOARD;
  }

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: Boolean(user?.id),
        user: user as IUser,
        doesPreviousUserExists,
        previousUserToken: previousUser?.token,
        setUser,
        token,
        setToken,
        logout,
        switchBackToPreviousUser,
        loginAsSomeUser,
        loginAsAdvertiser,
        loggedInAdvertiserId:
          user?.Role.name === USER_TYPE.ADVERTISER || user?.asAdvertiser
            ? user?.asAdvertiser || user?.companyId
            : undefined,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext can only be used inside UserProvider");
  }
  return context;
}

interface IUserContext {
  isAuthenticated: boolean;
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  doesPreviousUserExists: boolean;
  previousUserToken: string | undefined;
  loggedInAdvertiserId?: number; // As there can be multiple ways a user can be an logged in as an Advertiser ( Direct login & Login As Advertiser), we needed to distinguish it at a unified place
  logout: () => void;
  switchBackToPreviousUser: () => void;
  loginAsSomeUser: (args: { data: IUserLoginResponse }) => void;
  loginAsAdvertiser: (advertiser: IAdvertiser) => void;
}

function appendAdvertiserIdParamInRequest(stringifiedUser: string | null) {
  const user: IUser | null = stringifiedUser
    ? JSON.parse(stringifiedUser)
    : null;
  if (user && (user.Role.name === USER_TYPE.ADVERTISER || user.asAdvertiser)) {
    addRequestInterceptors({
      advertiserId: user.asAdvertiser || user.companyId,
    });
  }
}
