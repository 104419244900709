import client from "../client";
import { QueryFunctionContext } from "@tanstack/react-query";
import appendQueryParams from "@/utils/appendQueryParams";

export function getCampaignKeys(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/campaign-setup/fetch/campaignKeys`,
      data.meta?.queryParams ?? {}
    )
  );
}

export const getAssignedCampaigns = async (advertiserId = 0) => {
  return advertiserId
    ? client.get(
        `/admin-campaign-setup/assigned-campaigns?advertiserId=${advertiserId}`
      )
    : client.get(`/admin-campaign-setup/assigned-campaigns`);
};

export function createCampaign(payload: any) {
  return client.post("/campaigns", payload);
}

export function editCampaign({ id, payload }: { payload: any; id: string }) {
  return client.put(`/campaigns/${id}`, payload);
}

export function getCampaigns(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/campaigns", data.meta?.queryParams ?? {})
  );
}
