import { useDialogDispatch, useDialogState } from "@/context/DialogContext";
import * as React from "react";
import AlertDialog from "../AlertDialog";
import DefaultDialog from "../DefaultDialog";
import { CustomDialogProps } from "../dialog.interface";

const dialogObj = {
  default: ({
    setDialogState,
    dialogState,
  }: CustomDialogProps): React.ReactNode => (
    <DefaultDialog setDialogState={setDialogState} dialogState={dialogState} />
  ),
  alert: ({
    setDialogState,
    dialogState,
  }: CustomDialogProps): React.ReactNode => (
    <AlertDialog setDialogState={setDialogState} dialogState={dialogState} />
  ),
};
export default function CustomDialog(): ReturnType<React.FC> {
  const { dialogState } = useDialogState();
  const { setDialogState } = useDialogDispatch();

  return (
    <div>
      {dialogObj[(dialogState.variant ?? "default") as keyof typeof dialogObj]({
        setDialogState,
        dialogState,
      })}
    </div>
  );
}
