import routes from "@/routes";
import { lazy } from "react";
const Insight = lazy(() => import("@/modules/Insight"));
const ListInsight = lazy(() => import("@/modules/Insight/ListInsight"));
const InsightDetails = lazy(
  () => import("@/modules/Insight/Detail/InsightDetails")
);
const CompanyDetails = lazy(
  () => import("@/modules/Insight/Detail/CompanyDetails")
);
const InsightRoutes = {
  element: <Insight />,
  path: routes.VISITOR_INSIGHTS.INDEX,
  children: [
    {
      element: <ListInsight />,
      path: "",
      children: [
        { element: <InsightDetails />, path: "" },
        { element: <CompanyDetails />, path: routes.VISITOR_INSIGHTS.DETAIL() },
      ],
    },
  ],
};

export default InsightRoutes;
