export default function mapFilterQueryKeys(keys: unknown[] | undefined) {
  return keys && Array.isArray(keys) && keys.length > 0
    ? keys
        .filter((x) => {
          if (x === 0) return true;
          return x;
        })
        .map((x) => x.toString())
    : [];
}
