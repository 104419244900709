import usePermission from "@/hooks/usePermission";
import { Module, Scope } from "@/types";
import { ReactNode } from "react";

export default function PermissionGuard({
  children,
  module,
  permissionType,
  byPassPermission = false,
}: PermissionGuardProps) {
  let isPermitted;
  const { isAdmin, permission } = usePermission();
  if (module) {
    if (Array.isArray(module)) {
      isPermitted = module.some((x) =>
        checkPermission(permission[x]?.global, permissionType)
      );
    } else {
      isPermitted = checkPermission(permission[module]?.global, permissionType);
    }
  } else isPermitted = false;

  const isRender = isAdmin ? true : byPassPermission ? true : isPermitted;

  return <>{isRender && children}</>;
}
export type GuardPermissionType = keyof typeof GUARD_PERMISSION_TYPES;
export type PermissionGuardExternalProps = Omit<
  PermissionGuardProps,
  "children"
>;
interface PermissionGuardProps {
  children?: ReactNode;
  module?: Module | Module[];
  permissionType: GuardPermissionType;
  byPassPermission?: boolean;
}

export const GUARD_PERMISSION_TYPES = {
  create: "C",
  read: "R",
  update: "U",
  delete: "D",
} as const;

export function checkPermission(
  userScope: Scope | undefined,
  permissionType: GuardPermissionType
) {
  return Boolean(
    userScope && userScope.includes(GUARD_PERMISSION_TYPES[permissionType])
  );
}
