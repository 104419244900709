import { Box, Typography } from "@mui/material";

export default function PageStatusError({
  status,
  message,
  detail,
}: {
  status: 404 | 401 | 503;
  message: string;
  detail: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flexDirection: "column",
        gap: 1.4,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h1Semi"
          sx={{
            fontSize: { xs: "10rem", md: "12rem", lg: "14rem" },
          }}
        >
          {status}
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h1Semi">{message}</Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h4Reg">{detail}!</Typography>
      </Box>
    </Box>
  );
}
