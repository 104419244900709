import { Theme } from "@mui/material";

export const IconButtons = (theme: Theme) => ({
  MuiIconButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
