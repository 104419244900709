import { Theme } from "@mui/material";
import TableHead from "./tableHead";
import TableBody from "./tableBody";
import TableCell from "./tableCell";
import TableRow from "./tableRow";
import TablePagination from "./tablePagination";
import { FONT_FAMILY } from "@/constants";

const Table = (theme: Theme) => ({
  MuiTable: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontFamily: FONT_FAMILY,
      },
    },
  },
  ...TableHead(theme),
  ...TableBody(theme),
  ...TableRow(theme),
  ...TableCell(theme),
  ...TablePagination(theme),
});

export default Table;
