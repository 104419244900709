// local custom imports
import { FONT_FAMILY } from "@/constants";
import { FontSizeFunction } from "@/theme/typography/typography.interface";
import { Palette } from "@mui/material";

export const Bodys = (
  ResponsiveFontSizes: FontSizeFunction,
  palette: Palette
) => {
  return {
    bodyBigReg: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      ...ResponsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 18, xl: 18 }),
    },
    bodyBigSemi: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      ...ResponsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 18, xl: 18 }),
    },
    bodyBigBold: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "0.15px",
      ...ResponsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 18, xl: 18 }),
    },
    body1Reg: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "26px",
      letterSpacing: "0.44px",
      ...ResponsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 16, xl: 16 }),
    },
    body1Semi: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "26px",
      letterSpacing: "0.44px",
      ...ResponsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 16, xl: 16 }),
    },
    body1Bold: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "26px",
      letterSpacing: "0.44px",
      ...ResponsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 16, xl: 16 }),
    },
    body2Reg: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      ...ResponsiveFontSizes({ xs: 13, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
    body2Semi: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      ...ResponsiveFontSizes({ xs: 13, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
    body2Bold: {
      color: palette.text.primary,
      fontFamily: FONT_FAMILY,
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      ...ResponsiveFontSizes({ xs: 13, sm: 13, md: 14, lg: 14, xl: 14 }),
    },
  };
};
