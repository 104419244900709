import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const TableCell = (theme: Theme) => ({
  MuiTableCell: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontFamily: FONT_FAMILY,
        fontSize: "1rem",
        "&.MuiTableCell-body": {
          fontSize: "0.8125rem",
        },
      },
      body: {
        fontSize: "1rem",
      },
    },
  },
});

export default TableCell;
