import {
  useSnackbarDispatch,
  useSnackbarState,
} from "@/context/SnackbarContext";
import { Alert, Snackbar } from "@mui/material";

export default function CustomSnackbar() {
  const { snackbarState } = useSnackbarState();
  const { setSnackbarState } = useSnackbarDispatch();

  function handleSnackbar() {
    setSnackbarState({ open: false, message: "" });
  }

  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleSnackbar}
      {...snackbarState}
    >
      <Alert onClose={handleSnackbar} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
