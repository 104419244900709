import { formFieldError } from "@/types";
import labelRequired from "@/utils/labelRequired";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";

export default function CustomFormControl({
  label = null,
  children,
  required = false,
  disabled = false,
  error,
  toolTip = null,
}: CustomFormControlProps): ReturnType<React.FC> {
  return (
    <FormControl fullWidth disabled={disabled}>
      {label && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {labelRequired(label, required)}
          {toolTip && (
            <Tooltip title={toolTip} placement="top-start">
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      )}
      {children}
      {error && <FormHelperText error>{`${error}`}</FormHelperText>}
    </FormControl>
  );
}

interface CustomFormControlProps {
  label?: string | null;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: formFieldError;
  toolTip?: string | null;
}
