import PublicLayout from "@/layouts/PublicLayout";
import CompleteProfile from "@/modules/CompleteProfile";
import ForgetPassword from "@/modules/ForgetPassword";
import Login from "@/modules/Login";
import UpdatePassword from "@/modules/UpdatePassword";
import routes from "@/routes";
import { RouteObject } from "react-router-dom";

export const PublicRoutes: RouteObject[] = [
  {
    element: <PublicLayout />,
    path: "/",
    children: [
      {
        element: <Login />,
        path: "/",
      },
      {
        element: <Login isAdmin />,
        path: "/admin",
      },
      {
        element: <ForgetPassword />,
        path: routes.FORGET_PASSWORD,
      },
      {
        element: <UpdatePassword />,
        path: routes.UPDATE_PASSWORD,
      },
      {
        element: <CompleteProfile />,
        path: "/complete-profile",
      },
    ],
  },
];
