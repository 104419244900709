import { createTheme, Theme } from "@mui/material/styles";
import OverridesComponents from "./overridesComponets";
import palette from "./palette";
import Typography from "./typography";
import "./theme";
import config from "@/whiteLabel.config";
const appName: keyof typeof config = import.meta.env.VITE_APP;

let theme: Theme = createTheme({
  palette: palette(config[appName].theme),
});

theme = createTheme(theme, {
  typography: Typography(theme),
  components: OverridesComponents(theme),
});

export default theme;
