import routes from "@/routes";
import { lazy } from "react";
const Agency = lazy(() => import("@/modules/Agency"));
const ListAgency = lazy(() => import("@/modules/Agency/ListAgency"));
const CreateAgency = lazy(
  () => import("@/modules/Agency/CreateOrEditAgency/CreateAgency")
);
const EditAgency = lazy(
  () => import("@/modules/Agency/CreateOrEditAgency/EditAgency")
);

const AgencyRoutes = {
  element: <Agency />,
  path: routes.AGENCIES.INDEX,

  children: [
    {
      element: <ListAgency />,
      path: "",
    },
    {
      element: <CreateAgency />,
      path: routes.AGENCIES.CREATE,
    },
    {
      element: <EditAgency />,
      path: routes.AGENCIES.EDIT(),
    },
  ],
};

export default AgencyRoutes;
