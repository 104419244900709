import CustomLoader from "@/components/Loader/CustomLoader";
import CustomLinkTabs from "@/components/Tabs/CustomLinkTabs";
import routes from "@/routes";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabs = [
  {
    to: routes.USERS.INDEX,
    label: "User",
  },
  {
    to: routes.AGENCIES.INDEX,
    label: "Agency",
  },
  // { to: '/meetings', label: 'Meetings', icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} /> },
];

export default function Setup(): ReturnType<React.FC> {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  let tab = 0;

  if (pathname.includes(routes.USERS.INDEX)) tab = 0;
  else tab = 1;

  if (tab !== selectedTab) setSelectedTab(tab);

  useEffect(() => {
    if (pathname === "/dashboard/setup") navigate(routes.USERS.INDEX);
  }, [pathname]);

  if (!pathname) return <CustomLoader />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box>
        <CustomLinkTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Box>
      <Outlet />
    </Box>
  );
}
