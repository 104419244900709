import { getTargetAccountFiles } from "@/apis/v1/target-account";
import { useQuery } from "@tanstack/react-query";
import { ITargetAccountFiles } from "./targetAccounts.interface";
import { filterDataAccordingToAllowedAdvertisers } from "../Advertiser/advertiser.validation";
export const TARGET_ACCOUNT_FILE_KEY = (fileType: "domains" | "emails") => [
  "target-account",
  "files",
  fileType,
];
export function useTargetAccountFiles({
  queryParams,
  enabled = true,
}: {
  queryParams: ITargetAccountFiles;
  enabled?: boolean;
}) {
  return useQuery(
    TARGET_ACCOUNT_FILE_KEY(queryParams.fileType),
    getTargetAccountFiles,
    {
      enabled,
      meta: {
        queryParams,
      },
      select: filterDataAccordingToAllowedAdvertisers("companyId"),
    }
  );
}
