import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box, Typography, useTheme } from "@mui/material";
export default function PageError({ errorMessage }: { errorMessage: string }) {
  const { palette } = useTheme();
  const color = palette.grey[800];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <WarningAmberRoundedIcon
          sx={{
            fontSize: { xs: "16rem", md: "22rem", lg: "24rem" },
            color,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.4,
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h1Semi" sx={{ color }}>
            {errorMessage}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.4 }}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="body2Semi" sx={{ color }}>
              To refresh the page click the button below!
            </Typography>
          </Box>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <CustomLoadingButton
              title="Refresh"
              isLoading={false}
              sx={{ backgroundColor: color }}
              Icon={RefreshRoundedIcon}
              onClick={() => document.location.reload()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
