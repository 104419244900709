import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const TablePagination = (theme: Theme) => ({
  MuiTablePagination: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontFamily: FONT_FAMILY,
        fontSize: "0.875rem",
      },
      toolbar: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.875rem",
      },
      selectLabel: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.875rem",
      },
      selectRoot: {
        fontSize: "0.875rem",
      },
      select: {
        fontSize: "0.875rem",
      },
      input: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.875rem",
      },
      displayedRows: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.875rem",
      },
    },
  },
});

export default TablePagination;
