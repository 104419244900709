import { BORDER_RADIUS, FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const OutlinedInput = (theme: Theme) => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "label + &": {
          marginTop: theme.spacing(4.5),
        },
        border: "none",
        borderRadius: BORDER_RADIUS,
        position: "relative",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : "#2b2b2b",
        width: "100%",
        padding: "0 10px",
        "&.Mui-focused": {
          borderColor: theme.palette.accent.dark,
          borderWidth: "1px",
        },
      },
      notchedOutline: {
        border: "none",
      },
      input: {
        padding: "0 10px",
        minHeight: "44px",
        fontSize: "0.875rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
      },
    },
  },
});

export default OutlinedInput;
