import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export const createCampaignSetup = async (payload: any) => {
  return client.post("/admin-campaign-setup", payload);
};
export const updateCampaignSetup = async ({
  id,
  payload,
}: {
  id: any;
  payload: any;
}) => {
  return client.put(`/admin-campaign-setup/${id}`, payload);
};
export const deleteCampaignSetup = async (id: any) => {
  return client.delete(`/admin-campaign-setup/${id}`);
};
export const getCampaignSetup = async (data: QueryFunctionContext) => {
  return client.get(
    appendQueryParams("/admin-campaign-setup", data.meta?.queryParams ?? {})
  );
};
export function getLineItemsByAdvertiserId(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/lineItems`, data.meta?.queryParams ?? {})
  );
}
export function getReports(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/reportings", data.meta?.queryParams ?? {})
  );
}
