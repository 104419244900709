import routes from "@/routes";
import { lazy } from "react";
const Advertiser = lazy(() => import("@/modules/Advertiser"));
const ListAdvertiser = lazy(
  () => import("@/modules/Advertiser/ListAdvertiser")
);
const CreateOrEditAdvertiser = lazy(
  () => import("@/modules/Advertiser/CreateOrEditAdvertiser")
);

const AdvertiserRoutes = {
  element: <Advertiser />,
  path: routes.ADVERTISERS.INDEX,

  children: [
    {
      element: <ListAdvertiser />,
      path: "",
    },
    {
      element: <CreateOrEditAdvertiser />,
      path: routes.ADVERTISERS.CREATE,
    },
    {
      element: <CreateOrEditAdvertiser isEdit={true} />,
      path: routes.ADVERTISERS.EDIT(),
    },
  ],
};

export default AdvertiserRoutes;
