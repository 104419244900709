import { Theme } from "@mui/material";

export const Drawer = (theme: Theme) => ({
  MuiDrawer: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontSize: "1.2rem",
      },
      paper: {
        overflow: "visible",
        backgroundColor: theme.palette.secondary.main,
        color: "#ffffff",
      },
    },
  },
});
