import { Button, ButtonProps, CircularProgress } from "@mui/material";

export default function CustomLoadingButton({
  title,
  isLoading = true,
  Icon = null,
  iconLocation = "endIcon",
  variant = "custom1",
  // buttonProps,
  ...rest
}: CustomLoadingButtonProps) {
  const dynamicProp = Icon && {
    [iconLocation]: isLoading ? (
      <CircularProgress size="1rem" color="inherit" />
    ) : (
      Icon && <Icon />
    ),
  };

  return (
    <Button variant={variant} {...dynamicProp} {...rest}>
      {!Icon ? (
        isLoading ? (
          <CircularProgress size="1.54rem" color="inherit" />
        ) : (
          title
        )
      ) : (
        title
      )}
    </Button>
  );
}

interface CustomLoadingButtonProps extends ButtonProps {
  title: string;
  isLoading: boolean;
  Icon?: React.ElementType | null;
  iconLocation?: string;
}
