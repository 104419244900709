import { getTokenResponse, setNewPassword } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import routes from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

type ILogin = yup.InferType<typeof validationSchema>;
type Error = {
  errMsg: string;
};
export default function UpdatePassword() {
  const location = useLocation();
  const [token, setToken] = React.useState<string | null>(null);
  const {
    mutateAsync: setNewPasswordMutate,
    isLoading: setNewPasswordLoading,
    error: setNewPasswordError,
  } = useMutation(setNewPassword);
  const { data: tokenResponse } = useQuery(
    ["setPassword", token],
    getTokenResponse,
    {
      enabled: Boolean(token),
      meta: {
        token,
      },
      onSuccess: (data) => {
        if (!data) navigate(routes.LOGIN);
      },
    }
  );
  React.useEffect(() => {
    extractEmailFromToken();
  }, []);
  const extractEmailFromToken = async () => {
    const params = queryString.parse(location.search);
    setToken(params.token as string);
  };
  const { setSnackbarState } = useSnackbarDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  async function onFormSubmit(data: ILogin) {
    await setNewPasswordMutate({
      password: data.newPassword,
      email: tokenResponse.email,
    });
    setSnackbarState({
      open: true,
      message: messages.updatedSuccessfully("Password"),
    });
    navigate(routes.LOGIN);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: 13,
      }}
    >
      <Box>
        <FormHelperText error>
          {(setNewPasswordError as Error)?.errMsg}
        </FormHelperText>
      </Box>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Update password
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
        component="form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <CustomInput
          name="newPassword"
          label="New Password"
          inputType="password"
          control={control}
          error={errors?.newPassword?.message}
          type="secondary"
        />

        <CustomInput
          name="confirmPassword"
          label="Confirm Password"
          inputType="password"
          control={control}
          error={errors?.confirmPassword?.message}
          type="secondary"
        />

        <CustomLoadingButton
          type="submit"
          title="Reset password"
          isLoading={setNewPasswordLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        />
      </Box>
    </Box>
  );
}

const validationSchema = yup.object({
  newPassword: yup.string().required(messages.requiredField),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), ""], messages.passwordNotMatching)
    .required(messages.requiredField),
});
