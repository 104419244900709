import { capitalize } from "lodash";

export const campaignTypeList = (objectiveTypes: any) =>
  objectiveTypes?.map((item: any) => ({
    label: convertCase(item.name),
    value: item.id,
  }));

function convertCase(value: string) {
  return value
    .split("_")
    .map((item) => capitalize(item))
    .join(" ");
}
