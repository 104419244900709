import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CardContainer from "@/components/Card/CardContainer";
import TableItemPlaceHolder from "@/components/Placeholder/TableItemPlaceHolder";
import {
  ITableButton,
  ITableHeaders,
  ITablePaginaion,
  SearchField,
  SelectedAction,
  TableToolTipProps,
} from "@/components/Table/table.interface";
import { BORDER_RADIUS } from "@/constants";
import { InputFieldType, Order } from "@/types";
import getComparator from "@/utils/getComparator";
import stableSort from "@/utils/stableSort";
import { Button, SxProps, TablePagination, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { union, without } from "lodash";
import * as React from "react";
import EnhancedTableHead from "./EnhancedTableHeader";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

interface StyledTableRow extends TableRowProps {
  secondarytype?: "false" | "true";
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    boxShadow: "none",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.8125rem",
    border: "none",
  },
}));

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "lastIndex",
})<StyledTableRow>(({ theme, secondarytype, onClick }) => ({
  borderRadius: "25px",
  "td:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "td:last-child": {
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  backgroundColor:
    secondarytype === "true"
      ? theme.palette.background.paper
      : theme.palette.background.default,
  cursor: onClick ? "pointer" : "auto",
  "&:nth-of-type(odd)": {},
}));

interface KDATA {
  id: string | number;
}

export default function CustomTable<TDATA extends KDATA>({
  title,
  buttonName,
  ButtonIcon,
  buttonFunction,
  buttonDisabled,
  buttonLoading = false,
  secondaryButtonName,
  deleteButtonName,
  deleteButtonFunction,
  isLoadingDelete,
  isLoadingAdd,
  addButtonName,
  addButtonFunction,
  secondaryButtonFunction,
  ButtonIconSecondary,
  showButton = true,
  headers,
  rowData = [],
  tableRowComponent: TableRowComponent,
  tableMinimumWidth,
  buttonVariant = "custom1",
  pagination = true,
  isCheck = false,
  handleDelete,
  page = 0,
  handleChangePage = () => null,
  rowsPerPage = 10,
  handleChangeRowsPerPage = () => null,
  totalCount,
  tableRowComponentProps,
  type = "primary",
  isHeader = true,
  isLoading = false,
  sortByIndex = 0,
  sortBy = "asc",
  onRowClick,
  selectedActionObject,
  isClearSelected = false,
  setIsClearSelected,
  disableShadow = false,
  primaryButtonPermission,
  noOfItemsLoading,
  tableRowStyles,
  tableHeaderStyles = {},
  toolTipProps = {},
  isToolTip = false,
  cardStyles = {},
  searchField,
}: CustomTableProps<TDATA>) {
  const [order, setOrder] = React.useState<Order>(sortBy);
  const [orderBy, setOrderBy] = React.useState(headers[sortByIndex].id);
  const [selected, setSelected] = React.useState<Array<any>>([]);
  const countRef = React.useRef<number | null>(null);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (selected.length > 0) {
      const isSelected = rowData.every((x) => selected.includes(x.id));
      setIsAllSelected(isSelected);
    } else {
      if (isAllSelected) setIsAllSelected(false);
    }
  }, [rowData, selected]);

  React.useEffect(() => {
    if (isClearSelected) {
      setSelected([]);
      setIsClearSelected?.(false);
    }
  }, [isClearSelected]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = union(
        selected,
        rowData.map((n) => n.id)
      );
      setSelected(newSelected);
      return;
    }
    setSelected(without(selected, ...rowData.map((n) => n.id)));
  };
  const handleClickCheckBox = (
    event: React.MouseEvent<unknown>,
    name: string | number
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<any> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  if (totalCount != null && countRef.current !== totalCount) {
    countRef.current = totalCount;
  }

  const onCheckTableRowProps: any = isCheck
    ? {
        handleClickCheckBox,
        selected,
      }
    : null;
  return (
    <CardContainer
      sx={{
        padding: "2rem",
        display: "flex",
        maxWidth: "100%",
        width: "100%",
        ...cardStyles,
      }}
      shadow={!disableShadow}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "100%",
          height: "100%",
        }}
      >
        {isCheck && selected?.length > 0 ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 1 }}>
            <Button onClick={() => setSelected([])}>Clear All Selection</Button>
            {deleteButtonName && (
              <CustomLoadingButton
                variant={buttonVariant}
                onClick={() => {
                  deleteButtonFunction(selected);
                  setSelected([]);
                }}
                isLoading={isLoadingDelete ?? false}
                title={deleteButtonName}
              />
            )}
          </Box>
        ) : (
          addButtonName && (
            <Button variant={buttonVariant} onClick={() => addButtonFunction()}>
              {addButtonName}
            </Button>
          )
        )}
        {(title || buttonName || isCheck) && (
          <EnhancedTableToolbar
            buttonName={buttonName}
            ButtonIcon={ButtonIcon}
            buttonFunction={buttonFunction}
            buttonDisabled={buttonDisabled}
            buttonLoading={buttonLoading}
            primaryButtonPermission={primaryButtonPermission}
            secondaryButtonName={secondaryButtonName}
            ButtonIconSecondary={ButtonIconSecondary}
            secondaryButtonFunction={secondaryButtonFunction}
            showButton={showButton}
            buttonVariant={buttonVariant}
            searchField={searchField}
            title={title}
            numSelected={selected.length}
            rowCount={rowData?.length}
            onSelectAllClick={handleSelectAllClick}
            handleDelete={handleDelete}
            selected={selected}
            isHeader={isHeader}
            isCheck={isCheck}
            isAllSelected={isAllSelected}
            selectedActionObject={selectedActionObject}
          />
        )}
        <Box sx={{ overflow: "auto" }}>
          <Table
            sx={{
              minWidth: tableMinimumWidth ?? "800px",
              maxWidth: "100%",
              overflow: "auto",
              "-webkit-border-horizontal-spacing": "0",
              "-webkit-border-vertical-spacing": "1em",
              "border-collapse": "separate",
            }}
            aria-label="customized table"
          >
            {isHeader && (
              <EnhancedTableHead
                headCells={headers}
                orderBy={orderBy}
                order={order}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rowData?.length}
                onRequestSort={handleRequestSort}
                numSelected={selected.length}
                isCheck={isCheck}
                toolTipProps={toolTipProps}
                isToolTip={isToolTip}
                tableHeaderStyles={tableHeaderStyles}
              />
            )}
            <TableBody sx={{ borderRadius: BORDER_RADIUS }}>
              <>
                {isLoading ? (
                  <>
                    {[...Array(noOfItemsLoading ?? rowsPerPage).keys()].map(
                      (x, i) => (
                        <TableRowPlaceholder
                          key={i}
                          type={type}
                          headerLength={headers.length ?? 1}
                        />
                      )
                    )}
                  </>
                ) : (
                  <>
                    {stableSort(rowData, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const rowKey = row?.id ?? index;
                        return (
                          <StyledTableRow
                            key={rowKey}
                            secondarytype={
                              type === "secondary" ? "true" : "false"
                            }
                            sx={tableRowStyles}
                            onClick={
                              onRowClick ? () => onRowClick?.(row) : undefined
                            }
                          >
                            <TableRowComponent
                              row={row}
                              {...onCheckTableRowProps}
                              {...tableRowComponentProps}
                            />
                          </StyledTableRow>
                        );
                      }
                    )}
                  </>
                )}
              </>
            </TableBody>
          </Table>
        </Box>
      </Box>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={countRef.current ?? rowData?.length ?? -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ overflow: "hidden" }}
        />
      )}
    </CardContainer>
  );
}

type TableStyle = InputFieldType;

interface CustomTableProps<TDATA> extends ITableButton, ITablePaginaion {
  title?: string;
  headers: Array<ITableHeaders>;
  rowData: Array<Record<string, string | number | string[]> | TDATA>;
  tableRowComponent?: any;
  isCheck?: boolean;
  handleDelete?: any;
  tableRowComponentProps?: any;
  sortByIndex?: number;
  sortBy?: Order;
  type?: TableStyle;
  tableMinimumWidth?: string;
  isHeader?: boolean;
  isLoading?: boolean;
  onRowClick?: (row: { [x: string]: string | number }) => void;
  selectedActionObject?: SelectedAction[];
  isClearSelected?: boolean;
  setIsClearSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  disableShadow?: boolean;
  noOfItemsLoading?: number;
  tableRowStyles?: SxProps<Theme>;
  isToolTip?: boolean;
  toolTipProps?: TableToolTipProps;
  cardStyles?: SxProps;
  tableHeaderStyles?: SxProps;
  searchField?: SearchField;
}

function TableRowPlaceholder({ type, headerLength }: any) {
  return (
    <StyledTableRow secondarytype={type === "secondary" ? "true" : "false"}>
      <StyledTableCell colSpan={headerLength}>
        <TableItemPlaceHolder />
      </StyledTableCell>
    </StyledTableRow>
  );
}
