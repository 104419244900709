import CustomFormControl from "@/components/Form/FormControl/CustomFormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/system";
import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import { formFieldError, InputFieldType } from "@/types";
import labelRequired from "@/utils/labelRequired";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { secondaryStyles } from "../secondaryStyles";

export default function CustomSelect<T extends FieldValues>({
  label = null,
  placeholder,
  icon,
  position = "start",
  type = "primary",
  control,
  name,
  itemList = [],
  required,
  error,
  disabled = false,
  toolTip,
  loading = false,
}: CustomSelectProps<T>): ReturnType<React.FC> {
  const theme = useTheme();
  const { field, fieldState } = useController({ control, name });
  return (
    <CustomFormControl
      label={labelRequired(label, required)}
      error={error}
      disabled={disabled}
      toolTip={toolTip}
    >
      <Select
        sx={{ ...secondaryStyles(type, theme) }}
        startAdornment={icon && position === "start" ? icon : null}
        endAdornment={icon && position === "end" ? icon : null}
        IconComponent={ExpandMoreOutlinedIcon}
        displayEmpty={true}
        {...field}
        renderValue={
          field.value !== ""
            ? undefined
            : () => <> {loading ? "loading ..." : placeholder && placeholder}</>
        }
        disabled={disabled}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {itemList.map((x: Record<string, string | number>, i: number) => (
          <MenuItem key={x.value} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
}

interface CustomSelectProps<T extends FieldValues = FieldValues>
  extends UseControllerProps<T> {
  label?: string | null;
  placeholder?: string;
  icon?: JSX.Element;
  position?: string;
  type?: InputFieldType;
  itemList: Array<Record<string, string | number>>;
  required?: boolean;
  error?: formFieldError;
  disabled?: boolean;
  toolTip?: string | null;
  loading?: boolean;
}
