import routes from "@/routes";
import { lazy } from "react";
const Display = lazy(() => import("@/modules/Asset/LeadGen/Display"));

const DisplayLeadList = lazy(
  () => import("@/modules/Asset/LeadGen/Display/List")
);
const CreateOrEditLeadGen = lazy(
  () => import("@/modules/Asset/LeadGen/CreateOrEditLeadForm")
);
const DisplayLeadGen = {
  element: <Display />,
  path: routes.ASSETS.LEADGEN.LINKEDIN.INDEX,

  children: [
    {
      element: <DisplayLeadList />,
      path: "",
    },
    {
      element: <CreateOrEditLeadGen isLinkedin={false} />,
      path: routes.ASSETS.LEADGEN.CREATE,
    },
    {
      element: <CreateOrEditLeadGen isLinkedin={false} isEdit={true} />,
      path: routes.ASSETS.LEADGEN.EDIT(),
    },
  ],
};

export default DisplayLeadGen;
