import { setAuthHeader } from "@/apis/client";
import { login as adminLogin } from "@/apis/v1/admin";
import { login as userLogin } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import { getFirstTabPath, permittedTabs } from "@/components/Drawer/data";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import { TOKEN_KEY, USER_KEY, USER_TYPE } from "@/constants";
import { useUserContext } from "@/context/UserContext";
import routes from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import * as yup from "yup";

type ILogin = yup.InferType<typeof validationSchema>;
type Error = {
  errMsg: string;
};
export default function Login({ isAdmin = false }: LoginProps) {
  const { user, setUser, setToken, isAuthenticated } = useUserContext();
  const {
    mutateAsync: userLoginMutate,
    isLoading: isUserLoading,
    error: userError,
  } = useMutation(userLogin);
  const {
    mutateAsync: adminLoginMutate,
    isLoading: isAdminLoading,
    error: adminError,
  } = useMutation(adminLogin);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  async function onFormSubmit(data: ILogin) {
    const requestFn = isAdmin ? adminLoginMutate : userLoginMutate;

    const res = await requestFn(data);

    window.localStorage.setItem(TOKEN_KEY, res.token);
    window.localStorage.setItem(USER_KEY, JSON.stringify(res.user));
    setAuthHeader(res.token);
    setUser(res.user);
    setToken(res.token);
  }

  if (isAuthenticated) {
    const tabs =
      user &&
      permittedTabs(
        user?.Role?.$permissions,
        user?.Role?.name === USER_TYPE.SUPER_ADMIN
      );
    return <Navigate to={routes.DASHBOARD + "/" + getFirstTabPath(tabs)} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: 13,
      }}
    >
      <Box>
        <FormHelperText error>
          {isAdmin
            ? (adminError as Error)?.errMsg
            : (userError as Error)?.errMsg}
        </FormHelperText>
      </Box>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onFormSubmit)}>
        <CustomInput
          name="email"
          label="Email Address"
          control={control}
          error={errors?.email?.message}
        />
        <CustomInput
          name="password"
          label="Password"
          inputType="password"
          control={control}
          error={errors?.password?.message}
        />

        <CustomLoadingButton
          type="submit"
          title="Sign In"
          isLoading={isAdmin ? isAdminLoading : isUserLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        />
        {!isAdmin && (
          <Grid container>
            <Grid item xs>
              <Link href={routes.FORGET_PASSWORD} variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const validationSchema = yup.object({
  email: yup.string().email().required("Required"),
  password: yup.string().required("Required"),
});

interface LoginProps {
  isAdmin?: boolean;
}
