import { messages } from "@/messages";
import { UseFormGetValues, UseFormReset, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_USER_TYPE, CreateUserType } from "../ListUser";
import { useEffect, useState } from "react";
import {
  IntegrationTypeValue,
  anyValueTrue,
  defaultValuesOrPayloadPermission,
  modules,
} from "../data";
import { IPermissions } from "../user.interface";
import { Scope } from "@/types";

const DEFAULT_KEYS = {
  name: "name",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  companyId: "companyId",
};
export const ADDITIONAL_KEYS = {
  ...DEFAULT_KEYS,
  advertisersSpecificIds: "advertisersSpecificIds",
  advertiserId: "advertiserId",
};

export function useCreateOrEditForm({
  createUserType,
  requiredCompanyId,
  isEdit = false,
  isAdmin,
}: {
  createUserType?: CreateUserType;
  requiredCompanyId: number | null;
  isEdit?: boolean;
  isAdmin?: boolean;
}) {
  const schema = useValidationSchema({ isEdit });
  const [validationSchema, setValidationSchema] = useState(schema);
  const defaultValues = useDefaultValues({
    requiredCompanyId,
    isEdit,
    isAdmin,
    createUserType,
  });

  const rhForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const curryngResetValidationByName =
    resetValidationByName(setValidationSchema);
  return {
    rhForm,
    resetValidation,
    resetValidationByName: curryngResetValidationByName,
    setValidationSchema,
    defaultValues,
  };
}

function useDefaultValues({
  requiredCompanyId = null,
  isEdit = false,
  isAdmin,
  createUserType,
}: {
  requiredCompanyId: number | null;
  isEdit: boolean;
  isAdmin?: boolean;
  createUserType?: CreateUserType;
}) {
  const values: Record<string, string | number | any[] | null> = {
    [DEFAULT_KEYS.name]: "",
    [DEFAULT_KEYS.email]: "",
    [DEFAULT_KEYS.password]: "",
    [DEFAULT_KEYS.confirmPassword]: "",
    [DEFAULT_KEYS.companyId]: requiredCompanyId,
  };
  if (isAdmin && createUserType === CREATE_USER_TYPE.ADVERTISER_USER) {
    values[ADDITIONAL_KEYS.advertiserId] = null;
  }
  if (createUserType === CREATE_USER_TYPE.INTERNAL_USER) {
    values[ADDITIONAL_KEYS.advertisersSpecificIds] = [];
  }
  return values;
}

export function companyIdByUser(user: any, createUserType: CreateUserType) {
  const { isAdvertiser, isAgencyAdmin, isAgencyUser, companyId } = user;
  if (!isAdvertiser && (isAgencyAdmin || isAgencyUser)) {
    if (createUserType === CREATE_USER_TYPE.INTERNAL_USER) return companyId;
    else return null;
  }
  if (isAdvertiser) return companyId;

  return null;
}

function useValidationSchema({ isEdit }: { isEdit: boolean }) {
  return yup.object({
    companyId: yup.string().required(messages.requiredField).nullable(),
    name: yup.string().required(messages.requiredField),
    email: yup.string().email().required(messages.requiredField),
    advertisersSpecificIds: yup.array().notRequired(),
    ...passwordValidation(isEdit),
  });
}

export function useSetDefaultValueForPermissions({
  getValues,
  memoizedPermission,
  reset,
  depArray,
  createUserType,
  intialRender,
  isEdit,
}: {
  getValues: UseFormGetValues<any>;
  memoizedPermission: IPermissions;
  reset: UseFormReset<any>;
  depArray: any[];
  createUserType: CreateUserType;
  intialRender?: boolean;
  isEdit?: boolean;
}) {
  useEffect(() => {
    if (isEdit ? !intialRender : true) {
      const values = getValues();
      if (
        values &&
        (Array.isArray(values.companyId)
          ? values.companyId.length > 0
          : values.companyId) &&
        memoizedPermission &&
        createUserType === CREATE_USER_TYPE.INTERNAL_USER &&
        anyValueTrue(values)
      ) {
        const permittedModuleDefaultValues = defaultValuesOrPayloadPermission({
          modules: modules({ data: {} }),
          permissions: memoizedPermission,
          createUserType,
          type: "defaultValue",
        });
        reset({ ...values, ...permittedModuleDefaultValues });
      }
    }
  }, [...depArray]);
}

function resetValidationByName(
  setValidationSchema: React.Dispatch<React.SetStateAction<any>>
) {
  return (validation: (yup: any) => any) =>
    setValidationSchema((currentValues: any) =>
      yup.object({
        ...currentValues.fields,
        ...validation(yup),
      })
    );
}
function resetValidation(
  setValidationSchema: any,
  permittedDefaultKeys: any,
  createUserType?: CreateUserType,
  isAdmin?: boolean
) {
  const values: Record<any, any> = {};
  permittedDefaultKeys.forEach((x: any) => {
    if (moduleFieldValidation[x as keyof typeof moduleFieldValidation]) {
      values[x] =
        moduleFieldValidation[x as keyof typeof moduleFieldValidation];
    }
  });
  if (isAdmin && createUserType === CREATE_USER_TYPE.ADVERTISER_USER) {
    values["advertiserId"] = yup
      .string()
      .required(messages.requiredField)
      .nullable();
  }
  setValidationSchema((currentValues: any) =>
    yup.object({
      ...currentValues.fields,
      ...values,
    })
  );
}

function passwordValidation(isEdit: boolean) {
  if (isEdit) {
    return {
      password: yup.string(),
      confirmPassword: yup.string().when("password", {
        is: (password: string) => password.length,
        then: yup
          .string()
          .oneOf([yup.ref("password"), ""], messages.passwordNotMatching)
          .required(messages.requiredField),
        otherwise: yup
          .string()
          .test("empty Value", messages.confirmPasswordNotAllowed, (val) =>
            Boolean(!val?.length)
          ),
      }),
    };
  }

  return {
    password: yup.string().required(messages.requiredField),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], messages.passwordNotMatching)
      .required(messages.requiredField),
  };
}

const moduleFieldValidation = {
  dashboard: yup.boolean().required(messages.requiredField),
  accountAudience: yup.string().required(messages.requiredField).nullable(),
  siteVisitorAudience: yup.string().required(messages.requiredField).nullable(),
  emailAudience: yup.string().required(messages.requiredField).nullable(),
  visitorInsight: yup.boolean().required(messages.requiredField),
  contactBi: yup.boolean().required(messages.requiredField),
  abmStats: yup.boolean().required(messages.requiredField),
  attribution: yup.boolean().required(messages.requiredField),
  agencyUser: yup.string().required(messages.requiredField).nullable(),
  advertiserUser: yup.string().required(messages.requiredField).nullable(),
  creative: yup.string().required(messages.requiredField).nullable(),
  form: yup.string().required(messages.requiredField).nullable(),
  utm: yup.string().required(messages.requiredField).nullable(),
  tag: yup.string().required(messages.requiredField).nullable(),
  conversion: yup.string().required(messages.requiredField).nullable(),
  advertisers: yup.string().required(messages.requiredField).nullable(),
  leadAppend: yup.string().required(messages.requiredField).nullable(),
  integration: yup.string().required(messages.requiredField).nullable(),
  integrationType: yup.string().when("integration", {
    is: (val: Scope | "none") => val && val !== "none",
    then: yup.string().required(messages.requiredField).nullable(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  integrationChannels: yup.array().when("integrationType", {
    is: (val: IntegrationTypeValue) => val && val === "channel",
    then: yup
      .array()
      .min(1, messages.minimumValues("channel"))
      .required(messages.requiredField),
    otherwise: yup.array().notRequired().nullable(),
  }),
  campaigns: yup.string().required(messages.requiredField).nullable(),
  campaignsSpecificIds: yup.array().required(messages.requiredField),
  campaignStats: yup.string().required(messages.requiredField).nullable(),
  campaignStatsType: yup.string().when("campaignStats", {
    is: (val: Scope | "none") => val && val !== "none",
    then: yup.string().required(messages.requiredField).nullable(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  campaignStatsSpecificIds: yup.array().required(messages.requiredField),
  audienceManager: yup.string().required(messages.requiredField).nullable(),
  audienceManagerSpecificIds: yup.array().required(messages.requiredField),
  reportSetup: yup.string().required(messages.requiredField).nullable(),
  reportSetupSpecificIds: yup.array().required(messages.requiredField),
};
