import {
  ITableHeaders,
  TableToolTipProps,
} from "@/components/Table/table.interface";
import CustomIconTooltip from "@/components/Tooltip/CustomTooltip.tsx";
import { Order } from "@/types";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { SxProps } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { StyledTableCell } from ".";

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isCheck,
    toolTipProps,
    isToolTip,
    tableHeaderStyles,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any, index: number) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align ?? "left"}
            colSpan={headCell.col ?? 1}
            sx={{ ...tableHeaderStyles }}
          >
            {headCell.id && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: headCell?.noSort ? 0.5 : 0,
                }}
              >
                <>
                  {isCheck && index === 0 && (
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                    />
                  )}
                </>
                {headCell.noSort ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
                {isToolTip && (
                  <CustomIconTooltip
                    {...toolTipProps}
                    title={headCell.toolTip}
                  />
                )}
              </Box>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: Array<ITableHeaders>;
  isCheck: boolean;
  isToolTip: boolean;
  toolTipProps: TableToolTipProps;
  tableHeaderStyles: SxProps;
}
