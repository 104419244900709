import routes from "@/routes";
import { lazy } from "react";

const Utm = lazy(() => import("@/modules/Utm"));
const ListUtm = lazy(
  () => import("@/modules/Utm/ListUtm")
);
const CreateOrEditUtm = lazy(
  () => import("@/modules/Utm/CreateOrEditUtm")
);

const UtmRoutes = {
  element: <Utm />,
  path: routes.UTM.INDEX,

  children: [
    {
      element: <ListUtm />,
      path: "",
    },
    {
      element: <CreateOrEditUtm />,
      path: routes.UTM.CREATE,
    },
    {
      element: <CreateOrEditUtm isEdit />,
      path: routes.UTM.EDIT(),
    },
  ],
};

export default UtmRoutes;
