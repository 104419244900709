import {
  CHANNELS,
  CRM,
  DESTINATION,
  EXTENDED_CHANNELS_AGENCY_PERMISSIONS,
  EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS,
  MODULES,
  SCOPES,
  USER_TYPE,
} from "@/constants";
import {
  ChildModule,
  IPermissions,
  IntegrationPermissions,
  PermissionScope,
  RootModule,
  USER_TYPE_UNION,
  UserSettingModules,
} from "./user.interface";
import { messages } from "@/messages";
import { CREATE_USER_TYPE, CreateUserType } from "./ListUser";
import { UsePermission } from "@/hooks/usePermission";
import { Channel, Channel_CRM, Scope, ScopeWithNone } from "@/types";
import GeneralForm from "./CreateOrEditUser/GeneralForm";
import ModuleForm from "./CreateOrEditUser/ModuleForm";
import { ICompany } from "../Company/companies.interface";
import { ADDITIONAL_KEYS } from "./CreateOrEditUser/temp.hook";
import objectToLabelValue from "@/utils/objectToLabelValue";

export const STEPS = [0, 1] as const;
export const STEPS_LABEL = ["General Settings", "Module Settings"];
export const STEPFORM = {
  0: GeneralForm,
  1: ModuleForm,
} as const;
export const STEPButton = {
  0: "Next",
  1: "Back",
} as const;
export const SPECIFICIDS = "SpecificIds";
export const permissionTypes = [
  {
    label: "None",
    value: "none",
  },

  {
    label: "Read",
    value: "R",
  },
  {
    label: "Read & Update",
    value: "RU",
  },
  {
    label: "Read, Update, Create & Delete",
    value: "CRUD",
  },
];

const campaignStatsType = [
  {
    label: "Full",
    value: "full",
  },

  {
    label: "Minimal",
    value: "minimal",
  },
];
const integrationType = [
  {
    label: "All Channels",
    value: "all",
  },

  {
    label: "Select Channels",
    value: "channel",
  },
] as const;
export type IntegrationTypeValue = (typeof integrationType)[number]["value"];

export const integrationChannelsForAgencyUser = [
  ...objectToLabelValue(CHANNELS),
];
export const integrationChannelsForAdvertiserUser = [
  ...objectToLabelValue(DESTINATION),
  ...objectToLabelValue({ LINKEDIN: CHANNELS.LINKEDIN }),
  ...objectToLabelValue(CRM),
];

export function mapCompanyList({
  companies,
  id,
  isAdmin,
  filterType,
}: {
  companies: [ICompany];
  id: number;
  isAdmin?: boolean;
  filterType?: "advertiser" | "agency";
}) {
  if (isAdmin) {
    return companies
      ?.filter((x) =>
        filterType === "agency"
          ? !x.parentCompanyId
          : filterType === "advertiser"
          ? Number(x.parentCompanyId) === id
          : x
      )
      ?.map((item: ICompany) => ({
        label: item.name,
        value: item.id,
      }));
  }

  const mappedCompanies = companies?.map((item: ICompany) => ({
    label: item.name,
    value: item.id,
  }));
  if (id) {
    return mappedCompanies?.filter((x) => Number(x.value) !== id);
  }

  return mappedCompanies;
}

export const keyToPermissions = (permission: IPermissions) => ({
  account:
    permission[MODULES.AGENCY_USERS] || permission[MODULES.ADVERTISER_USERS],
  campaign:
    permission[MODULES.CAMPAIGNS] ||
    permission[MODULES.CAMPAIGN_STATS] ||
    permission[MODULES.ABM_STATS],
  other:
    permission[MODULES.INTEGRATIONS_SALESFORCE] ||
    permission[MODULES.INTEGRATIONS_HUBSPOT],
});

export function mapPermission(permissions: any, withScopeKey = false) {
  return permissions?.reduce(
    (
      acc: IPermissions,
      val: { type: keyof IPermissions; scope: PermissionScope }
    ) => ({
      ...acc,
      [val.type]: withScopeKey ? { global: val.scope } : val.scope,
    }),
    {}
  );
}

export function findCompanyById(companies: any, selectedCompanyId: number) {
  return companies.find((company: any) => company.id === selectedCompanyId);
}
export function checkLowestPermission(companyScope: any, userScope: any) {
  for (const val of SCOPES) {
    if (companyScope.global === val || userScope.global === val) {
      return { global: val };
    }
  }
}

export function scopeValues(
  scope: (typeof SCOPES)[number] | undefined,
  valueToFilter: ScopeWithNone[] = [],
  bypassPermission = false,
  permissionFilter: string[] = []
) {
  let permissionTypesClone = permissionTypes;
  if (valueToFilter.length) {
    permissionTypesClone = permissionTypes.filter(
      (x) => !valueToFilter?.includes(x.value as ScopeWithNone)
    );
  }
  if (bypassPermission) {
    return permissionTypesClone.filter(
      (x) => !permissionFilter.includes(x.value)
    );
  }
  if (scope === "R") {
    return permissionTypesClone.filter(
      (x) => x.value !== "RU" && x.value !== "CRUD"
    );
  } else if (scope === "RU") {
    return permissionTypesClone.filter((x) => x.value !== "CRUD");
  } else if (scope === "CRUD") {
    return permissionTypesClone;
  }
}
export function userEditPermission({
  userPermission,
  companyPermission,
  editorsPermission,
  users,
  createUserType,
}: {
  userPermission: IPermissions;
  companyPermission: IPermissions;
  editorsPermission: IPermissions;
  users: {
    isAdmin: boolean;
    isAdvertiser: boolean;
    isAgencyAdmin: boolean;
    isAgencyUser: boolean;
  };
  createUserType: CreateUserType;
}) {
  if (!companyPermission || !userPermission) return {};
  const { isAdmin, isAdvertiser, isAgencyAdmin, isAgencyUser } = users;
  if (isAdmin) {
    return companyPermission;
  } else {
    type keyType = keyof IPermissions;
    const permissions: IPermissions = Object?.keys(companyPermission)?.reduce(
      (acc, val) => {
        if (editorsPermission[val as keyType]) {
          return (acc = {
            ...acc,
            [val]: checkLowestPermission(
              companyPermission[val as keyType],
              editorsPermission[val as keyType]
            ),
          });
        }
        return acc;
      },
      {}
    );

    if (!isAdvertiser && (isAgencyAdmin || isAgencyUser)) {
      if (createUserType === CREATE_USER_TYPE.ADVERTISER_USER) {
        if (companyPermission[MODULES.INTEGRATIONS_LINKEDIN]) {
          permissions[MODULES.INTEGRATIONS_LINKEDIN] =
            companyPermission[MODULES.INTEGRATIONS_LINKEDIN];
        }
        if (companyPermission[MODULES.INTEGRATIONS_HUBSPOT]) {
          permissions[MODULES.INTEGRATIONS_HUBSPOT] =
            companyPermission[MODULES.INTEGRATIONS_HUBSPOT];
        }
        if (companyPermission[MODULES.INTEGRATIONS_SALESFORCE]) {
          permissions[MODULES.INTEGRATIONS_SALESFORCE] =
            companyPermission[MODULES.INTEGRATIONS_SALESFORCE];
        }
        if (companyPermission[MODULES.INTEGRATIONS_DISPLAYVIDEO360]) {
          permissions[MODULES.INTEGRATIONS_DISPLAYVIDEO360] =
            companyPermission[MODULES.INTEGRATIONS_DISPLAYVIDEO360];
        }
        if (companyPermission[MODULES.INTEGRATIONS_TRADEDESK]) {
          permissions[MODULES.INTEGRATIONS_TRADEDESK] =
            companyPermission[MODULES.INTEGRATIONS_TRADEDESK];
        }
      }
    }

    return permissions;
  }
}
export function userOrCompanyPermission(
  permissionObj: UsePermission,
  companyPermission: IPermissions,
  createUserType: CreateUserType
) {
  const { permission, isAdmin, isAdvertiser, isAgencyAdmin, isAgencyUser } =
    permissionObj;
  if (!companyPermission && !permission) return {};
  if (isAdmin) {
    return companyPermission;
  }

  if (!isAdvertiser && (isAgencyAdmin || isAgencyUser)) {
    if (createUserType === CREATE_USER_TYPE.INTERNAL_USER) {
      return permission;
    }
    if (createUserType === CREATE_USER_TYPE.ADVERTISER_USER) {
      type keyType = keyof IPermissions;
      const agencyUserPermission: IPermissions = Object?.keys(
        companyPermission
      )?.reduce((acc, val) => {
        if (permission[val as keyType]) {
          return (acc = {
            ...acc,
            [val]: checkLowestPermission(
              companyPermission[val as keyType],
              permission[val as keyType]
            ),
          });
        }
        return acc;
      }, {});
      if (companyPermission[MODULES.INTEGRATIONS_LINKEDIN]) {
        agencyUserPermission[MODULES.INTEGRATIONS_LINKEDIN] =
          companyPermission[MODULES.INTEGRATIONS_LINKEDIN];
      }
      if (companyPermission[MODULES.INTEGRATIONS_HUBSPOT]) {
        agencyUserPermission[MODULES.INTEGRATIONS_HUBSPOT] =
          companyPermission[MODULES.INTEGRATIONS_HUBSPOT];
      }
      if (companyPermission[MODULES.INTEGRATIONS_SALESFORCE]) {
        agencyUserPermission[MODULES.INTEGRATIONS_SALESFORCE] =
          companyPermission[MODULES.INTEGRATIONS_SALESFORCE];
      }
      if (companyPermission[MODULES.INTEGRATIONS_DISPLAYVIDEO360]) {
        agencyUserPermission[MODULES.INTEGRATIONS_DISPLAYVIDEO360] =
          companyPermission[MODULES.INTEGRATIONS_DISPLAYVIDEO360];
      }
      if (companyPermission[MODULES.INTEGRATIONS_TRADEDESK]) {
        agencyUserPermission[MODULES.INTEGRATIONS_TRADEDESK] =
          companyPermission[MODULES.INTEGRATIONS_TRADEDESK];
      }
      return agencyUserPermission;
    }
  }

  if (isAdvertiser) {
    return permission;
  }

  return null;
}

export const modules: UserSettingModules = ({ data }) => ({
  dashboard: {
    heading: "Dashboard",
    rootPermission: [MODULES.DASHBOARD],
    data: [
      {
        componentName: "CheckBoxModule",
        componentData: [
          {
            text: messages.userModuleAccess("Dashboard"),
            required: false,
            name: "dashboard",
            dependent: null,
            permission: MODULES.DASHBOARD,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: false,
          },
        ],
      },
    ],
  },

  integration: {
    heading: "Integrations",
    rootPermission: [
      MODULES.INTEGRATIONS_BEESWAX,
      MODULES.INTEGRATIONS_HUBSPOT,
      MODULES.INTEGRATIONS_LINKEDIN,
      MODULES.INTEGRATIONS_SALESFORCE,
      MODULES.INTEGRATIONS_DISPLAYVIDEO360,
      MODULES.INTEGRATIONS_TRADEDESK,
    ],
    data: [
      {
        componentName: "ModuleFilesAccessPermission",
        componentData: [
          {
            label: "Integrations",
            required: false,
            name: "integration",
            dependent: "integrationType",
            permission: MODULES.INTEGRATIONS_TRADEDESK,
            fileFieldName: "integrationChannels",
            fileData: data?.integrationChannels,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
            placeholders: [null, "Channels"],
            dependentCondition: (val: IntegrationTypeValue) =>
              !val || val === "all",
            bypassPermission: true,
            scopeFilter: ["R", "RU"],
            extraFields: [
              {
                required: false,
                bypassPermission: true,
                name: "integrationType",
                dependent: "integration",
                permission: MODULES.CAMPAIGN_STATS_COMPLETE,
                fileData: integrationType,
                allowedUserType: valueof(CREATE_USER_TYPE),
                defaultValue: null,
                dependentCondition: (val: null | Scope | "none") =>
                  !val || val === "none",
                placeholder: "Select Integration Mode",
                editValue: (val) => (val ? "full" : "channel"),
                componentProps: {
                  disableClearable: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  campaign: {
    heading: "Campaigns",
    rootPermission: [MODULES.CAMPAIGNS],
    data: [
      {
        componentName: "ModuleFilesAccessPermission",
        componentData: [
          {
            label: "Campaigns",
            required: false,
            name: "campaigns",
            dependent: "campaigns",
            permission: MODULES.CAMPAIGNS,
            fileData: data?.campaigns,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
            dependentCondition: (val: null | Scope | "none") =>
              !val || val === "none",
          },
        ],
      },
    ],
  },
  campaignStats: {
    heading: "Campaign Stats",
    rootPermission: [MODULES.CAMPAIGN_STATS],
    data: [
      {
        componentName: "ModuleFilesAccessPermission",
        componentData: [
          {
            label: "Campaign Stats",
            required: false,
            name: "campaignStats",
            dependent: "campaignStats",
            permission: MODULES.CAMPAIGN_STATS,
            fileData: data?.campaignStats,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
            dependentCondition: (val: null | Scope | "none") =>
              !val || val === "none",
            extraFields: [
              {
                required: false,
                name: "campaignStatsType",
                dependent: "campaignStats",
                permission: MODULES.CAMPAIGN_STATS_COMPLETE,
                fileData: campaignStatsType,
                allowedUserType: valueof(CREATE_USER_TYPE),
                defaultValue: null,
                dependentCondition: (val: null | Scope | "none") =>
                  !val || val === "none",
                placeholder: "Select Campaign Stats type ",
                editValue: (val) => (val ? "full" : "minimal"),
              },
            ],
          },
        ],
      },
    ],
  },

  audienceManager: {
    heading: "Audience Manager",
    rootPermission: [MODULES.AUDIENCE_MANAGER],
    data: [
      {
        componentName: "ModuleFilesAccessPermission",
        componentData: [
          {
            required: false,
            name: "audienceManager",
            dependent: "audienceManager",
            permission: MODULES.AUDIENCE_MANAGER,
            fileData: data?.targetAccountFiles,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
            dependentCondition: (val: null | Scope | "none") =>
              !val || val === "none",
          },
        ],
      },
    ],
  },
  advertisers: {
    heading: "Advertisers",
    rootPermission: [MODULES.ADVERTISERS],
    rootAllowedUserType: [CREATE_USER_TYPE.INTERNAL_USER],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Advertiser",
            required: false,
            name: "advertisers",
            dependent: null,
            allowedUserType: [CREATE_USER_TYPE.INTERNAL_USER],
            permission: MODULES.ADVERTISERS,
            defaultValue: null,
            valueToFilter: ["none"],
          },
        ],
      },
    ],
  },
  audience: {
    heading: "Audience",
    rootPermission: [
      MODULES.AUDIENCE_EMAIL,
      MODULES.AUDIENCE_ACCOUNT,
      MODULES.AUDIENCE_SITE_VISITOR,
    ],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Account Audience",
            required: false,
            name: "accountAudience",
            dependent: null,
            permission: MODULES.AUDIENCE_ACCOUNT,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
          },
          {
            label: "Site Visitor Audience",
            required: false,
            name: "siteVisitorAudience",
            dependent: null,
            permission: MODULES.AUDIENCE_SITE_VISITOR,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
          },
          {
            label: "Email Audience",
            required: false,
            name: "emailAudience",
            dependent: null,
            permission: MODULES.AUDIENCE_EMAIL,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: null,
          },
        ],
      },
    ],
  },
  userManagement: {
    heading: "Users Management",
    rootPermission: [MODULES.AGENCY_USERS, MODULES.ADVERTISER_USERS],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Agency Users",
            required: false,
            name: "agencyUser",
            allowedUserType: [CREATE_USER_TYPE.INTERNAL_USER],
            permission: MODULES.AGENCY_USERS,
            defaultValue: null,
          },
          {
            label: "Advertiser Users",
            required: false,
            name: "advertiserUser",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.ADVERTISER_USERS,
            defaultValue: null,
          },
        ],
      },
    ],
  },
  asset: {
    heading: "Assets",
    rootPermission: [MODULES.CREATIVES, MODULES.FORMS, MODULES.UTMS],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Creatives",
            required: false,
            name: "creative",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.CREATIVES,
            defaultValue: null,
          },
          {
            label: "Forms",
            required: false,
            name: "form",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.FORMS,
            defaultValue: null,
          },
          {
            label: "UTMs",
            required: false,
            name: "utm",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.UTMS,
            defaultValue: null,
          },
        ],
      },
    ],
  },
  tagManager: {
    heading: "Tag Manager",
    rootPermission: [MODULES.TAGS, MODULES.CONVERSIONS],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Tags",
            required: false,
            name: "tag",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.TAGS,
            defaultValue: null,
          },
          {
            label: "Conversions",
            required: false,
            name: "conversion",
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.CONVERSIONS,
            defaultValue: null,
          },
        ],
      },
    ],
  },
  leadAppend: {
    heading: "Intent Stream",
    rootPermission: [MODULES.LEAD_APPEND],
    data: [
      {
        componentName: "ModuleAccessPermission",
        componentData: [
          {
            label: "Intent Stream",
            required: false,
            name: "leadAppend",
            dependent: null,
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.LEAD_APPEND,
            defaultValue: null,
            valueToFilter: ["RU"],
            labelModifier: (scopeList) =>
              scopeList.map((item) =>
                item.value === "CRUD"
                  ? { label: "Read & Delete", value: "CRUD" }
                  : item
              ),
          },
        ],
      },
    ],
  },
  visitorInsight: {
    heading: "Visitor Insights",
    rootPermission: [MODULES.VISITOR_INSIGHTS],
    data: [
      {
        componentName: "CheckBoxModule",
        componentData: [
          {
            text: messages.userModuleAccess("Visitor Insights"),
            required: false,
            name: "visitorInsight",
            dependent: null,
            permission: MODULES.VISITOR_INSIGHTS,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: false,
          },
        ],
      },
    ],
  },

  contactBi: {
    heading: "Contact BI",
    rootPermission: [MODULES.CONTACT_BI],
    data: [
      {
        componentName: "CheckBoxModule",
        componentData: [
          {
            text: messages.userModuleAccess("Contact BI"),
            required: false,
            name: "contactBi",
            dependent: null,
            permission: MODULES.CONTACT_BI,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: false,
          },
        ],
      },
    ],
  },
  report: {
    heading: "Report Setup",
    rootPermission: [
      MODULES.REPORTS_SETUP,
      MODULES.ABM_STATS,
      MODULES.ATTRIBUTION,
    ],
    data: [
      {
        componentName: "ModuleFilesAccessPermission",
        componentData: [
          {
            name: "reportSetup",
            dependent: "reportSetup",
            required: false,
            fileData: data?.report,
            allowedUserType: valueof(CREATE_USER_TYPE),
            permission: MODULES.REPORTS_SETUP,
            defaultValue: null,
            dependentCondition: (val: null | Scope | "none") =>
              !val || val === "none",
          },
        ],
      },
      {
        componentName: "CheckBoxModule",
        componentData: [
          {
            text: messages.userModuleAccess("ABM Stats"),
            required: false,
            name: "abmStats",
            dependent: "reportSetup",
            permission: MODULES.ABM_STATS,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: false,
            dependentCondition: (val: null | Scope | "none") =>
              !val || val === "none",
          },
          {
            text: messages.userModuleAccess("Attribution"),
            required: false,
            name: "attribution",
            permission: MODULES.ATTRIBUTION,
            allowedUserType: valueof(CREATE_USER_TYPE),
            defaultValue: false,
          },
        ],
      },
    ],
  },
});

export function defaultValuesOrPayloadPermission({
  modules,
  permissions,
  createUserType,
  type,
}: {
  modules: Record<string, RootModule>;
  permissions: IPermissions;
  createUserType: CreateUserType;
  type: "defaultValue" | "permission";
}) {
  const reqValues: Record<string, any> = {};
  Object.keys(modules).map((key) => {
    const rootPermission = modules[key as keyof typeof modules].rootPermission;
    if (rootPermission.some((permission) => permissions[permission])) {
      modules[key].data.map((child: ChildModule, i: number) => {
        child.componentData.forEach((x) => {
          const isPermitted = isRenderComponent({
            data: x,
            permissions,
            createUserType,
          });
          if (isPermitted) {
            const val = type === "defaultValue" ? x.defaultValue : x.permission;
            reqValues[x.name] = val;
            if (child.componentName === "ModuleFilesAccessPermission") {
              if (type === "defaultValue") {
                if (x.name === "integration") {
                  reqValues["integrationChannels"] = [];
                } else reqValues[`${x.name}SpecificIds`] = [];
              }
              if (type === "permission") {
                if (x.name === "integration") {
                  reqValues["integrationChannels"] =
                    integrationChannelsPermission({
                      permissions,
                      modulePermissions:
                        rootPermission as (keyof IntegrationPermissions)[],
                    });
                } else reqValues[`${x.name}SpecificIds`] = x.permission;
              }
            }
            if (x?.extraFields?.length) {
              x.extraFields.forEach((field) => {
                reqValues[field.name] =
                  type === "defaultValue"
                    ? field.defaultValue
                    : field.permission;
              });
            }
          }
        });
      });
    }
  });

  return reqValues;
}

export function editDefaultValues({
  modules,
  permissions,
  permissionOfUserToBeModified,
  companyPermission,
  createUserType,
}: {
  modules: Record<string, RootModule>;
  permissions: IPermissions;
  permissionOfUserToBeModified: IPermissions;
  companyPermission: IPermissions;
  createUserType: CreateUserType;
}) {
  const reqValues: Record<string, any> = {};
  Object.keys(modules).map((key) => {
    const rootPermission = modules[key as keyof typeof modules].rootPermission;
    if (rootPermission.some((permission) => permissions[permission])) {
      modules[key].data.map((child: ChildModule, i: number) => {
        child.componentData.forEach((x) => {
          const isPermitted =
            x.bypassPermission ?? Boolean(permissions[x.permission]);
          if (isPermitted) {
            const val = typeToRequiredValue({
              componentName: child.componentName,
              permission: x.permission,
              permissionOfUserToBeModified,
              name: x.name,
              rootPermission,
            });
            reqValues[x.name] = val;
            if (child.componentName === "ModuleFilesAccessPermission") {
              if (x.name === "integration") {
                const companyIntegration = integrationChannelsByUserPermissions(
                  { createUserType, permissions: companyPermission }
                );
                const userIntegration = integrationChannelsByUserPermissions({
                  createUserType,
                  permissions: permissionOfUserToBeModified,
                });
                if (userIntegration?.length) {
                  if (companyIntegration.length === userIntegration.length) {
                    reqValues["integrationType"] = "all";
                    reqValues["integrationChannels"] = [];
                  } else {
                    reqValues["integrationType"] = "channel";
                    reqValues["integrationChannels"] = userIntegration.map(
                      (channel) => channel.value
                    );
                  }
                } else {
                  reqValues["integrationType"] = null;
                  reqValues["integrationChannels"] = [];
                }
              } else {
                if (permissionOfUserToBeModified[x.permission]) {
                  const ids = Object.keys(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    permissionOfUserToBeModified[x.permission]!
                  ).filter((x) => x !== "global");

                  reqValues[`${x.name}SpecificIds`] = ids.length ? ids : [];
                } else reqValues[`${x.name}SpecificIds`] = [];

                if (x?.extraFields?.length) {
                  x.extraFields.forEach((field) => {
                    if (permissionOfUserToBeModified[x.permission]) {
                      reqValues[field.name] = field.editValue(
                        permissionOfUserToBeModified[field.permission]
                      );
                    } else reqValues[field.name] = field.defaultValue;
                  });
                }
              }
            }
          }
        });
      });
    }
  });

  return reqValues;
}

function typeToRequiredValue({
  componentName,
  permission,
  permissionOfUserToBeModified,
  name,
  rootPermission,
}: any) {
  if (componentName === "CheckBoxModule") {
    return permissionOfUserToBeModified[permission] ? true : false;
  }
  if (
    componentName === "ModuleAccessPermission" ||
    componentName === "ModuleFilesAccessPermission"
  ) {
    if (name === "integration") {
      if (
        rootPermission.some(
          (permission: keyof IPermissions) =>
            permissionOfUserToBeModified[permission]
        )
      ) {
        return "CRUD";
      } else {
        return "none";
      }
    }

    return permissionOfUserToBeModified[permission]?.global ?? "none";
  }
}

const valueof = (obj: Record<string, any>) => Object.values(obj);
export const isRenderComponent = ({
  data,
  permissions,
  createUserType,
  isEdit = false,
}: any): boolean => {
  return (
    (isEdit || data.allowedUserType.includes(createUserType)) &&
    (data.bypassPermission ?? Boolean(permissions[data.permission]))
  );
};
export function filterGlobalKey(obj: any) {
  if (obj) return Object.keys(obj)?.filter((x) => x !== "global");
  return;
}

export function filterSpecifiIds(data: any, ids: any[] = [], isEdit = false) {
  if (!isEdit && ids.length)
    return data?.filter((item: any) => ids.includes(String(item.value)));
  return data;
}

export function booleanFieldsPermissionScope(val: string) {
  if (val === "dashboard" || val === "abmStats") {
    return "R";
  }
  return "CRUD";
}

export function permissionMouleByUserType({
  userRole,
  userCompanyId,
  listUsercompanyId,
}: {
  userRole: USER_TYPE_UNION;
  userCompanyId: number;
  listUsercompanyId: number;
}) {
  if (
    userRole === USER_TYPE.AGENCY_ADMIN ||
    userRole === USER_TYPE.AGENCY_USER
  ) {
    return userCompanyId === listUsercompanyId
      ? MODULES.AGENCY_USERS
      : MODULES.ADVERTISER_USERS;
  }
  if (userRole === USER_TYPE.ADVERTISER) {
    return MODULES.ADVERTISER_USERS;
  }
}

export function anyValueTrue(values: Record<string, any>) {
  for (const key in values) {
    if (
      !ADDITIONAL_KEYS[key as keyof typeof ADDITIONAL_KEYS] &&
      ((Array.isArray(values[key]) && values[key].length) ||
        (!Array.isArray(values[key]) && values[key]))
    ) {
      return true;
    }
  }
  return false;
}

function integrationChannelsPermission({
  modulePermissions,
  permissions,
}: {
  modulePermissions: (keyof IntegrationPermissions)[];
  permissions: IPermissions;
}) {
  for (const permission of modulePermissions) {
    if (permissions[permission]) return permission;
  }
}

export function integrationChannelsByUserPermissions({
  createUserType,
  permissions,
}: {
  createUserType: CreateUserType;
  permissions: IPermissions;
}) {
  return (
    createUserType === CREATE_USER_TYPE.INTERNAL_USER
      ? integrationChannelsForAgencyUser
      : integrationChannelsForAdvertiserUser
  ).filter((channel) => {
    return permissions[
      (createUserType === CREATE_USER_TYPE.INTERNAL_USER
        ? EXTENDED_CHANNELS_AGENCY_PERMISSIONS[channel.value as Channel]
        : EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS[
            channel.value as Exclude<Channel_CRM, "display">
          ]) as keyof typeof permissions
    ];
  });
}
