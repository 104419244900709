import routes from "@/routes";
import { lazy } from "react";
const Linkedin = lazy(() => import("@/modules/Asset/LeadGen/Linkedin"));

const LinkedinLeadList = lazy(
  () => import("@/modules/Asset/LeadGen/Linkedin/List")
);
const CreateOrEditLeadGen = lazy(
  () => import("@/modules/Asset/LeadGen/CreateOrEditLeadForm")
);
const LinkedinLeadGen = {
  element: <Linkedin />,
  path: routes.ASSETS.LEADGEN.LINKEDIN.INDEX,

  children: [
    {
      element: <LinkedinLeadList />,
      path: "",
    },
    {
      element: <CreateOrEditLeadGen isLinkedin={true} />,
      path: routes.ASSETS.LEADGEN.CREATE,
    },
    {
      element: <CreateOrEditLeadGen isLinkedin={true} isEdit={true} />,
      path: routes.ASSETS.LEADGEN.EDIT(),
    },
  ],
};

export default LinkedinLeadGen;
