import { red } from "@mui/material/colors";
import paletteValues from "./data";

const palette = (value: string) => {
  if (value) return paletteValues[value];
  return paletteValues["palette1"];
};

export default palette;

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
    tertiary: Palette["primary"];
    quaternary: Palette["primary"];
    negative: Palette["primary"];
    positive: Palette["primary"];
    inactive: Palette["primary"];
  }
  interface PaletteOptions {
    accent: PaletteOptions["primary"];
    tertiary: PaletteOptions["primary"];
    quaternary: PaletteOptions["primary"];
    negative: PaletteOptions["primary"];
    positive: PaletteOptions["primary"];
    inactive: Palette["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}
