import routes from "@/routes";
import * as React from "react";
const SiteVisiter = React.lazy(() => import("@/modules/SiteVisiter"));
const SiteVisiterList = React.lazy(() => import("@/modules/SiteVisiter/List"));
const PixelRoutes = {
  element: <SiteVisiter />,
  path: routes.SITE_VISITER.INDEX,
  children: [
    {
      element: <SiteVisiterList />,
      path: "",
    },
  ],
};

export default PixelRoutes;
