import { useTheme } from "@mui/system";
import { IconProps } from "./icon.interface";

export default function EditIcon({
  primaryColor,
  secondaryColor = "#fff",
  disabled = false,
}: IconProps) {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill={
          disabled
            ? theme.palette.inactive.main
            : primaryColor ?? theme.palette.secondary.main
        }
      />
      <g clipPath="url(#clip0_1977_3524)">
        <path
          d="M11.4167 7.33334H7.33341C7.024 7.33334 6.72725 7.45626 6.50846 7.67505C6.28966 7.89384 6.16675 8.19059 6.16675 8.50001V16.6667C6.16675 16.9761 6.28966 17.2728 6.50846 17.4916C6.72725 17.7104 7.024 17.8333 7.33341 17.8333H15.5001C15.8095 17.8333 16.1062 17.7104 16.325 17.4916C16.5438 17.2728 16.6667 16.9761 16.6667 16.6667V12.5833"
          stroke={secondaryColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7917 6.45832C16.0238 6.22626 16.3386 6.09589 16.6667 6.09589C16.9949 6.09589 17.3097 6.22626 17.5417 6.45832C17.7738 6.69039 17.9042 7.00513 17.9042 7.33332C17.9042 7.66151 17.7738 7.97626 17.5417 8.20832L12.0001 13.75L9.66675 14.3333L10.2501 12L15.7917 6.45832Z"
          stroke={secondaryColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1977_3524">
          <rect
            width="14"
            height="14"
            fill={secondaryColor}
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
