import routes from "@/routes";
import * as React from "react";
const CreateSalesForce = React.lazy(
  () => import("@/modules/SalesForce/CreateSalesForce")
);
const CreateHubSpot = React.lazy(
  () => import("@/modules/HubSpot/CreateHubSpot")
);
const AudienceManager = React.lazy(() => import("@/modules/AudienceManager"));
const AudienceManagerFileList = React.lazy(
  () => import("@/modules/AudienceManager/FileDomainOrEmailList")
);
const ListAudienceManager = React.lazy(
  () => import("@/modules/AudienceManager/List")
);
const EmailAudienceRoutes = {
  element: <AudienceManager />,
  path: routes.AUDIENCE_MANAGER.INDEX,
  children: [
    {
      element: <ListAudienceManager />,
      path: "",
    },
    {
      element: <AudienceManagerFileList />,
      path: routes.AUDIENCE_MANAGER.VIEW(),
    },
    {
      element: <CreateSalesForce />,
      path: routes.AUDIENCE_MANAGER.CREATE_SALESFORCE,
    },
    {
      element: <CreateHubSpot />,
      path: routes.AUDIENCE_MANAGER.CREATE_HUBSPOT,
    },
  ],
};

export default EmailAudienceRoutes;
