import LinkTab from "@/components/Tab/LinkTab";
import HorizontalTabs from "../HorizontalTabs";
import { CustomTabsProps, TabLink } from "../tabs.interface";
function a11yProps(index: number) {
  return {
    id: `link-tab-${index}`,
    "aria-controls": `link-tabpanel-${index}`,
  };
}
export default function CustomLinkTabs({
  tabs,
  selectedTab,
  setSelectedTab,
  tabStyles,
  variant,
}: CustomLinkedTabs) {
  return (
    <HorizontalTabs
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabStyles={tabStyles}
      variant={variant}
    >
      {tabs.map((tab: TabLink, index: number) => (
        <LinkTab
          key={index}
          link={tab.to}
          label={tab.label}
          disabled={tab?.disabled}
          a11yProps={a11yProps(index)}
          styles={tab?.styles}
        />
      ))}
    </HorizontalTabs>
  );
}

interface CustomLinkedTabs extends Omit<CustomTabsProps, "children"> {
  tabs: TabLink[];
}
