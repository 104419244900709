import routes from "@/routes";
import { lazy } from "react";

const CampaignStats = lazy(() => import("@/modules/Report/CampaignStats"));

const CampaignStatsRoutes = {
  element: <CampaignStats />,
  path: routes.REPORTS.STATS.INDEX,
};

export default CampaignStatsRoutes;
