import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import SpacingGrid from "@/components/Grid/SpacingGrid";
import { Box, Typography } from "@mui/material";
import { AccountPage } from ".";

export default function AccountDetail({
  data,
  setActivePage,
}: AccountDetailProps) {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3Semi">Account Information </Typography>
      </Box>
      <Box sx={{}}>
        <SpacingGrid itemContainer spacingType="layout">
          {data.map((x, i) => (
            <Row key={i} {...x} isLastItem={Boolean(data.length - 1 === i)} />
          ))}
        </SpacingGrid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomLoadingButton
          title="Change Password"
          isLoading={false}
          onClick={() => setActivePage("Form")}
        />
      </Box>
    </>
  );
}

interface AccountDetailProps {
  data: any[];
  setActivePage: React.Dispatch<React.SetStateAction<AccountPage>>;
}

function Row({
  label,
  value,
  isLastItem,
}: {
  label: string;
  value: string;
  isLastItem: boolean;
}) {
  const boderBottom = isLastItem
    ? {
        borderBottomWidth: "1px",
        borderBottomStyle: "dotted",
        pb: 2.5,
      }
    : {};
  return (
    <SpacingGrid itemContainer sx={{ py: 2 }} rowSpacing={2.5}>
      <SpacingGrid
        item
        xs={4}
        sx={{
          textAlign: "start",

          borderTopWidth: "1px",
          borderTopStyle: "dotted",
          ...boderBottom,
        }}
      >
        <Typography variant="body2Semi">{label}</Typography>
      </SpacingGrid>
      <SpacingGrid
        item
        xs={4}
        sx={{
          textAlign: "center",
          borderTopWidth: "1px",
          borderTopStyle: "dotted",
          ...boderBottom,
        }}
      >
        <Typography variant="body2Reg">:</Typography>
      </SpacingGrid>
      <SpacingGrid
        item
        xs={4}
        sx={{
          textAlign: "end",
          wordBreak: "break-word",
          borderTopWidth: "1px",
          borderTopStyle: "dotted",
          ...boderBottom,
        }}
      >
        <Typography variant="body2Reg">{value}</Typography>
      </SpacingGrid>
    </SpacingGrid>
  );
}
