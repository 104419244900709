import { BORDER_RADIUS, FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const Select = (theme: Theme) => ({
  MuiSelect: {
    styleOverrides: {
      // Name of the slot
      nativeInput: {
        padding: "0 5px",
        minHeight: "44px",
        fontSize: "0.875rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
      },
      outlined: {
        border: "none",
        borderRadius: BORDER_RADIUS,
        position: "relative",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : "#2b2b2b",
        width: "100%",
        minHeight: "44px",
        minWidth: "60px",
        padding: "0 10px",
        display: "flex",
        alignItems: "center",
      },
    },
  },
});

export default Select;
