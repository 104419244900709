import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";

const TableHead = (theme: Theme) => ({
  MuiTableHead: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontFamily: FONT_FAMILY,
        fontSize: "1rem",
      },
    },
  },
});

export default TableHead;
