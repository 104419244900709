import routes from "@/routes";
import { IPermissions } from "../User/user.interface";

export const regions = [
  { label: "North America", value: "NAM" },
  { label: "Europe", value: "EMEA" },
  { label: "Asia Pacific", value: "APAC" },
];
export const displayFieldsList = [
  {
    name: "regions",
    label: "Select Region(s)",
    placeholder: "Region",
    itemList: regions,
    required: true,
    valueContainsLabel: false,
  },
  {
    name: "jobFunctions",
    label: "Select Job Functions",
    placeholder: "Function",
    valueContainsLabel: false,
    itemList: [
      {
        label: "Consulting",
        value: "Consulting",
      },
      {
        label: "Engineering & Technical",
        value: "Engineering & Technical",
      },
      {
        label: "Finance",
        value: "Finance",
      },
      {
        label: "General Management",
        value: "General Management",
      },
      {
        label: "Human Resources",
        value: "Human Resources",
      },
      {
        label: "Legal",
        value: "Legal",
      },
      {
        label: "Marketing",
        value: "Marketing",
      },
      {
        label: "Medical & Health",
        value: "Medical & Health",
      },
      {
        label: "Operations",
        value: "Operations",
      },
      {
        label: "Sales",
        value: "Sales",
      },
      {
        label: "Scientists",
        value: "Scientists",
      },
    ],
    required: true,
  },
  {
    name: "managementLevel",
    label: "Select Job Level",
    placeholder: "Level",
    valueContainsLabel: false,
    itemList: [
      {
        label: "Non-Manager",
        value: "Non-Manager",
      },
      {
        label: "Manager",
        value: "Manager",
      },
      {
        label: "Director",
        value: "Director",
      },
      {
        label: "VP-Level",
        value: "VP-Level",
      },
      {
        label: "C-Level",
        value: "C-Level",
      },
      {
        label: "Staff",
        value: "Staff",
      },
      {
        label: "CxO",
        value: "CxO",
      },
    ],
    required: true,
  },
];

export const linkedInFieldsList = [
  {
    name: "locations",
    label: "Enter Location",
    placeholder: "Location",
    valueContainsLabel: false,
    required: false,
  },
  {
    name: "jobFunctions",
    label: "Select Job Functions",
    placeholder: "Function",
    required: true,
    valueContainsLabel: false,
  },
  {
    name: "managementLevel",
    label: "Select Job Level",
    placeholder: "Level",
    required: true,
    valueContainsLabel: false,
  },
  {
    name: "titles",
    label: "Enter Job Title",
    placeholder: "Title",
    required: false,
    valueContainsLabel: false,
  },
  {
    name: "ageRanges",
    label: "Enter Age Ranges",
    placeholder: "Age Ranges",
    required: false,
    valueContainsLabel: false,
  },
  {
    name: "industries",
    label: "Enter Industries",
    placeholder: "Industries",
    required: false,
    valueContainsLabel: false,
  },
  {
    name: "genders",
    label: "Enter Gender",
    placeholder: "Gender",
    required: false,
    valueContainsLabel: false,
  },
  {
    name: "skills",
    label: "Enter Skills",
    placeholder: "Skill",
    required: false,
    valueContainsLabel: false,
  },
  {
    name: "yearOfExperienceList",
    label: "Enter year of experience",
    placeholder: "Year of experience",
    required: false,
    valueContainsLabel: false,
  },
];

export const AccountOrEmailheaders = [
  { id: "segment", label: "Segment", col: 2 },
  { id: "status", label: "Status", col: 1.24 },
  { id: "channel", label: "Channel", col: 1.24 },
  { id: "totalDomain", label: "Total Domains", col: 1.24 },
  { id: "coveredDomain", label: "Covered Domains", col: 1.4 },
  { id: "coverage", label: "Coverage (%)", col: 1.24 },
  { id: "count", label: "User Count", col: 1.24 },
  { id: "action", label: "Actions", col: 2.4 },
];
export function permittedAudienceTabs(
  permission: IPermissions,
  isAdmin: boolean
) {
  const tabList: any[] = [];
  Object.keys(tabs).forEach((module) => {
    if (isAdmin || permission[module as keyof IPermissions]) {
      tabList.push(tabs[module as keyof typeof tabs]);
    }
  });
  return tabList;
}
const tabs = {
  AUDIENCE_ACCOUNT: {
    to: routes.AUDIENCES.ACCOUNT_AUDIENCE.INDEX,
    label: "Account Audience",
    filter: true,
  },
  AUDIENCE_EMAIL: {
    to: routes.AUDIENCES.EMAIL_AUDIENCES.INDEX,
    label: "Contact Audience",
    filter: true,
  },
  AUDIENCE_SITE_VISITOR: {
    to: routes.AUDIENCES.RETARGETING.INDEX,
    label: "Retargeting Audience",
    filter: true,
  },
  AUDIENCE_MANAGER: {
    to: routes.AUDIENCE_MANAGER.INDEX,
    label: "Audience Manager",
    filter: true,
  },
};

export function emptyTableText(
  advertiserId: number | null,
  isLoading: boolean
) {
  if (advertiserId && !isLoading) {
    return "No Data Found";
  }

  return "Select an Advertiser";
}
