import {
  createCampaignSetup,
  deleteCampaignSetup,
  getCampaignSetup,
  updateCampaignSetup,
} from "@/apis/v1/reports";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

export const LINE_ITEMS_BY_ADVERTISERID_KEY = (advertiserId: number | null) => [
  "lineItems",
  advertiserId,
];
export const GET_CAMPAIGN_SETUP_KEY = (keys?: unknown[]) => [
  "campaign-setup",
  "get",
  ...mapFilterQueryKeys(keys),
];
export function useCampaignSetup(args: any = {}) {
  const { queryParams = {}, keys = [], options = {} } = args;
  return useQuery(GET_CAMPAIGN_SETUP_KEY(keys), getCampaignSetup, {
    meta: {
      queryParams,
    },
    ...options,
  });
}
export function useCreateCampaignSetup() {
  return useMutation(createCampaignSetup);
}
export function useDeleteCampaignSetup() {
  return useMutation(deleteCampaignSetup);
}
export function useUpdateCampaignSetup() {
  return useMutation(updateCampaignSetup);
}

export function useReportSetupFilterForm(data?: any) {
  const defaultValues = useDefaultValues(data);

  const rhForm = useForm<any>({
    defaultValues,
  });

  return { rhForm };
}
function useDefaultValues({ isAdvertiser, companyId }: any) {
  return {
    advertiserId: isAdvertiser ? companyId : "all",
    campaignId: null,
  };
}
