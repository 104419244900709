import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// custom import
import Image from "@/components/Image";
import { BRAND } from "@/constants";

export default function CustomLoader() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Image
          img={BRAND.logo}
          alt="loading-image"
          maxWidth="340px"
          maxHeight="340px"
        />
        <Box
          sx={{
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
      </Box>
    </Backdrop>
  );
}
