import routes from "@/routes";
import { lazy } from "react";

const AccountAudience = lazy(
  () => import("@/modules/Audience/AccountAudience")
);
const ListAudience = lazy(
  () => import("@/modules/Audience/AccountAudience/ListAccountAudience")
);
const CreateAudience = lazy(
  () => import("@/modules/Audience/AccountAudience/CreateAccountAudience")
);
const EditAudience = lazy(
  () => import("@/modules/Audience/AccountAudience/EditAccountAudience")
);

const UserRoutes = {
  element: <AccountAudience />,
  path: routes.AUDIENCES.ACCOUNT_AUDIENCE.INDEX,

  children: [
    {
      element: <ListAudience />,
      path: "",
    },
    {
      element: <CreateAudience />,
      path: routes.AUDIENCES.ACCOUNT_AUDIENCE.CREATE,
    },
    {
      element: <EditAudience />,
      path: routes.AUDIENCES.ACCOUNT_AUDIENCE.EDIT(),
    },
  ],
};

export default UserRoutes;
