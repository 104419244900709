import { forgotPassword } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

type ILogin = yup.InferType<typeof validationSchema>;
type Error = {
  errMsg: string;
};
export default function ForgetPassword() {
  const {
    mutateAsync: userForgetPasswordMutate,
    isLoading: isForgetPasswordLoading,
    error: forgetPasswordError,
  } = useMutation(forgotPassword);
  const { setSnackbarState } = useSnackbarDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationSchema),
  });

  async function onFormSubmit(data: ILogin) {
    await userForgetPasswordMutate(data);
    setSnackbarState({
      open: true,
      message: messages.forgetPassword,
    });
    navigate("/");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: 13,
      }}
    >
      <Box>
        <FormHelperText error>
          {(forgetPasswordError as Error)?.errMsg}
        </FormHelperText>
      </Box>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
        component="form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <CustomInput
          name="email"
          label="Email Address"
          control={control}
          placeholder="Email address"
          error={errors?.email?.message}
        />
        <CustomLoadingButton
          type="submit"
          title="Reset Password"
          isLoading={isForgetPasswordLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        />
        <Grid container>
          <Grid item xs>
            <Link href="/" variant="body2">
              Go Back to Login?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const validationSchema = yup.object({
  email: yup.string().email().required("Required"),
});
