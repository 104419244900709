import appendQueryParams from "@/utils/appendQueryParams";
import client from "../client";
import { QueryFunctionContext } from "@tanstack/react-query";
import { IAudiencePayload } from "@/modules/Linkedin/linkedin.interface";
export function getLinkedinAuthLink() {
  return client.get(`/linkedin/authLink`);
}
export function getLinkedinCampaign(data: QueryFunctionContext) {
  return client.get(`/linkedin/campaigns/${data.meta?.id}`);
}
export function getAudienceById(data: QueryFunctionContext) {
  return client.get(`/linkedin/audiences/${data.meta?.id}`);
}
export function getLinkedinBillingAdmin() {
  return client.get(`/linkedin/users/billingAdmin`);
}
export function getAdAccounts() {
  return client.get(`/linkedin/adAccounts`);
}
export function createAudience(payload: IAudiencePayload) {
  return client.post(`/linkedin/audiences`, payload);
}
export function runAudience(id: number) {
  return client.post(`/linkedin/audiences/${id}/run`);
}

export function editAudience({
  id,
  payload,
}: {
  id: any;
  payload: IAudiencePayload;
}) {
  return client.put(`/linkedin/audiences/${id}`, payload);
}
export function getLinkedinAudiences(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/linkedin/audiences`, data.meta?.queryParams ?? {})
  );
}
export function getAdFacets(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/linkedin/adFacets/${data.meta?.facetType}`,
      data.meta?.queryParams ?? {}
    )
  );
}
export function getPages() {
  return client.get(`/linkedin/pages`);
}
