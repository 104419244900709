import routes from "@/routes";
import { lazy } from "react";
const LeadAppend = lazy(() => import("@/modules/Report/LeadAppend"));
const ListLeadAppend = lazy(() => import("@/modules/Report/LeadAppend/List"));
const DetailLeadAppend = lazy(
  () => import("@/modules/Report/LeadAppend/Detail")
);

const LeadAppendRoutes = {
  element: <LeadAppend />,
  path: routes.REPORTS.LEAD_APPEND.INDEX,

  children: [
    {
      element: <ListLeadAppend />,
      path: "",
    },
    {
      element: <DetailLeadAppend />,
      path: routes.REPORTS.LEAD_APPEND.DETAIL(),
    },
  ],
};

export default LeadAppendRoutes;
