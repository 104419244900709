import routes from "@/routes";
import { lazy } from "react";
const Setup = lazy(() => import("@/modules/Report/ReportSetup"));
const ListCampaignSetup = lazy(
  () => import("@/modules/Report/ReportSetup/List")
);
const CreateCampaignSetup = lazy(
  () => import("@/modules/Report/ReportSetup/create")
);

const AbmRoutes = {
  element: <Setup />,
  path: routes.REPORTS.REPORTS_SETUP.INDEX,
  children: [
    {
      element: <ListCampaignSetup />,
      path: "",
    },
    {
      element: <CreateCampaignSetup />,
      path: routes.REPORTS.REPORTS_SETUP.CREATE,
    },
    {
      element: <CreateCampaignSetup />,
      path: routes.REPORTS.REPORTS_SETUP.EDIT(),
    },
  ],
};

export default AbmRoutes;
