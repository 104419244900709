import routes from "@/routes";
import { lazy } from "react";
const Display = lazy(() => import("@/modules/Asset/Creative/Display"));
const DisplayListAsset = lazy(
  () => import("@/modules/Asset/Creative/Display/List")
);
const DisplayDetailAsset = lazy(
  () => import("@/modules/Asset/Creative/DetailAsset/index")
);
const CreateOrEditAsset = lazy(
  () => import("@/modules/Asset/Creative/CreateOrEditAsset")
);

const AssetRoutes = {
  element: <Display />,
  path: routes.ASSETS.CREATIVES.DISPLAY.INDEX,

  children: [
    {
      element: <DisplayListAsset />,
      path: "",
    },
    {
      element: <DisplayDetailAsset isLinkedin={false} />,
      path: routes.ASSETS.CREATIVES.DETAIL(),
    },
    {
      element: <CreateOrEditAsset isLinkedin={false} />,
      path: routes.ASSETS.CREATIVES.CREATE,
    },
    {
      element: <CreateOrEditAsset isLinkedin={false} isEdit={true} />,
      path: routes.ASSETS.CREATIVES.EDIT(),
    },
  ],
};

export default AssetRoutes;
