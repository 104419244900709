import { Container } from "@mui/system";
import * as React from "react";

export default function CustomContainer({ children }: CustomContainerProps) {
  return <Container maxWidth="lg">{children}</Container>;
}

interface CustomContainerProps {
  children: React.ReactNode;
}
