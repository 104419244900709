import routes from "@/routes";
import { lazy } from "react";

const EmailAudience = lazy(() => import("@/modules/Audience/EmailAudience"));
const ListEmailAudience = lazy(
  () => import("@/modules/Audience/EmailAudience/ListEmailAudience")
);
const CreateEmailAudience = lazy(
  () => import("@/modules/Audience/EmailAudience/CreateEmailAudience")
);
const EditEmailAudience = lazy(
  () => import("@/modules/Audience/EmailAudience/EditEmailAudience")
);

const EmailAudienceRoutes = {
  element: <EmailAudience />,
  path: routes.AUDIENCES.EMAIL_AUDIENCES.INDEX,

  children: [
    {
      element: <ListEmailAudience />,
      path: "",
    },
    {
      element: <CreateEmailAudience />,
      path: routes.AUDIENCES.EMAIL_AUDIENCES.CREATE,
    },
    {
      element: <EditEmailAudience />,
      path: routes.AUDIENCES.EMAIL_AUDIENCES.EDIT(),
    },
  ],
};

export default EmailAudienceRoutes;
