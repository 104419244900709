import { DialogVariant } from "@/types";
import { CSSObject, DialogProps } from "@mui/material";
import * as React from "react";

const DialogStateContext = React.createContext<IDialogStateContext | null>(
  null
);
const DialogDispatchContext =
  React.createContext<IDialogDispatchContext | null>(null);

const defaultValue = {
  open: false,
  title: "",
};

export function DialogProvider({ children }: { children: React.ReactNode }) {
  const [dialogState, setDialogState] =
    React.useState<CustomDialogProps>(defaultValue);

  function closeDialog() {
    setDialogState(defaultValue);
  }

  return (
    <DialogStateContext.Provider value={{ dialogState }}>
      <DialogDispatchContext.Provider
        value={{ setDialogState, closeDialog, dialogOpen: dialogState.open }}
      >
        {children}
      </DialogDispatchContext.Provider>
    </DialogStateContext.Provider>
  );
}

export function useDialogState() {
  const context = React.useContext(DialogStateContext);
  if (!context) {
    throw new Error("useDialogState can only be used inside DialogProvider");
  }
  return context;
}

export function useDialogDispatch() {
  const context = React.useContext(DialogDispatchContext);
  if (!context) {
    throw new Error("useDialogDispatch can only be used inside DialogProvider");
  }
  return context;
}

export interface IDialogStateContext {
  dialogState: CustomDialogProps;
}
export interface IDialogDispatchContext {
  setDialogState: React.Dispatch<React.SetStateAction<CustomDialogProps>>;
  closeDialog: () => void;
  dialogOpen: boolean;
}

interface CustomDialogProps extends DialogProps {
  open: boolean;
  onCloseAction?: (props?: any) => void;
  title: string;
  variant?: DialogVariant;
  children?: React.ReactElement;
  buttonFunction?: (buttonFunctionParams?: any) => void;
  buttonName?: string;
  buttonLoading?: boolean;
  buttonFunctionParams?: any;
  styles?: CSSObject;
  childProps?: Record<any, any>;
}
