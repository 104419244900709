import { useUserContext } from "@/context/UserContext";
import routes from "@/routes";
import {
  Navigate,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import PageError from "./PageError";
import PageStatusError from "./PageStatusError";

export default function ErrorBoundary() {
  const error = useRouteError();
  const { isAuthenticated } = useUserContext();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      if (error.error?.message.includes("fetch dynamically")) {
        window.location.reload();
      }
      if (!isAuthenticated) {
        return <Navigate to={routes.LOGIN} replace />;
      }
      return (
        <PageStatusError
          status={error.status}
          message="Not Found"
          detail="The page requested could not be found on this server"
        />
      );
    }

    if (error.status === 401) {
      return (
        <PageStatusError
          status={error.status}
          message="Not Authorized"
          detail="You are not authorized to access this resource"
        />
      );
    }

    if (error.status === 503) {
      return (
        <PageStatusError
          status={error.status}
          message="Service Unavailable"
          detail="Looks like the api you are trying to reach is down"
        />
      );
    }
  }

  return <PageError errorMessage="Something went wrong" />;
}
