import routes from "@/routes";
import { lazy } from "react";
import DisplayAsset from "./displayAssets";
import LinkedinAsset from "./linkedinAssets";
const Asset = lazy(() => import("@/modules/Asset/Creative"));

const AssetRoutes = {
  element: <Asset />,
  path: routes.ASSETS.CREATIVES.INDEX,

  children: [LinkedinAsset, DisplayAsset],
};

export default AssetRoutes;
