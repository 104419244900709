import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CustomTableButtonProps } from ".";

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    title,
    buttonName,
    ButtonIcon,
    ButtonIconSecondary,
    buttonFunction,
    secondaryButtonName,
    secondaryButtonFunction,
    showButton = true,
    buttonVariant,
    handleDelete = () => null,
    selected,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        justifyContent: "space-between",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography variant="bodyBigBold">{title}</Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        showButton && (
          <div style={{ display: "flex", gap: "10px" }}>
            {buttonName && (
              <Button
                variant={buttonVariant}
                startIcon={ButtonIcon && <ButtonIcon />}
                onClick={buttonFunction}
              >
                {buttonName}
              </Button>
            )}

            {secondaryButtonName && (
              <Button
                variant={buttonVariant}
                startIcon={ButtonIconSecondary && <ButtonIconSecondary />}
                onClick={secondaryButtonFunction}
              >
                {secondaryButtonName}
              </Button>
            )}
          </div>
        )
      )}
    </Toolbar>
  );
}

interface EnhancedTableToolbarProps extends CustomTableButtonProps {
  numSelected: number;
  title?: string;
  handleDelete?: any;
  selected?: Array<any>;
}
