import routes from "@/routes";
import { lazy } from "react";
const LineItem = lazy(() => import("@/modules/LineItem"));
const ListLineItem = lazy(() => import("@/modules/LineItem/ListLineItem"));
const DetailCampaign = lazy(() => import("@/modules/Campaign/DetailCampaign"));

const CampaignRoutes = {
  element: <LineItem />,
  path: routes.CAMPAIGNS.LINE_ITEMS.INDEX(),
  children: [
    { element: <ListLineItem />, path: "" },
    {
      element: <DetailCampaign />,
      path: routes.CAMPAIGNS.LINE_ITEMS.EDIT(),
    },
  ],
};

export default CampaignRoutes;
