import * as React from "react";
import { getCampaignKeys, getCampaigns } from "@/apis/v1/campaign";
import { useQuery } from "@tanstack/react-query";
import { filterDataAccordingToAllowedAdvertisers } from "../Advertiser/advertiser.validation";
import { MODULES } from "@/constants";
import { validationSchema } from "./CreateOrEditCampaign/campaign.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useUserContext } from "@/context/UserContext";
import dayjs from "dayjs";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";
import { omit } from "lodash";

export function useCampaignKeysList(
  otherParams?: any,
  queryParams?: Record<string, any>
) {
  return useQuery(["campaigns", "keys"], getCampaignKeys, {
    ...otherParams,
    meta: {
      queryParams: {
        type: MODULES.CAMPAIGNS,
        ...queryParams,
      },
    },
  });
}

export const CAMPAIGNS_LIST_KEY = (keys?: Array<string | number | null>) => [
  "campaigns",
  ...mapFilterQueryKeys(keys),
];

interface useCampaignsListArgs {
  queryParams: {
    module: string;
    advertiserId?: number | null;
    page?: number;
    size?: number;
    isPagination?: boolean;
  };
}
export function useCampaignsList(meta?: useCampaignsListArgs) {
  return useQuery(
    CAMPAIGNS_LIST_KEY(
      Object.values(omit(meta?.queryParams, "isPagination") || [])
    ),
    getCampaigns,
    {
      meta: {
        queryParams: meta?.queryParams,
      },
      select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
    }
  );
}

export function useCreateOrEditForm(data?: any) {
  const defaultValues = useDefaultValues(data);
  const switchState = useSwitchState({
    isDisplay: Boolean(data?.["BeeswaxCampaign"]),
    isLinkedin: Boolean(data?.["LinkedinCampaign"]),
  });

  const campaignValidationSchema = validationSchema({
    display: switchState.display.state,
    linkedin: switchState.linkedin.state,
  });

  const rForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(campaignValidationSchema),
  });

  return { rForm, switchState };
}

function useSwitchState(value: { isDisplay: boolean; isLinkedin: boolean }) {
  const [displaySwitch, setDisplaySwitch] = React.useState<boolean>(
    value.isDisplay ?? false
  );
  const [linkedinSwitch, setLinkedinSwitch] = React.useState<boolean>(
    value.isLinkedin ?? false
  );
  return {
    display: { state: displaySwitch, setState: setDisplaySwitch },
    linkedin: { state: linkedinSwitch, setState: setLinkedinSwitch },
  };
}

function useDefaultValues(data?: any) {
  const { loggedInAdvertiserId } = useUserContext();
  if (data) {
    let editDefaultValues: any = {
      name: data?.name ?? "",
      advertiserCompanyId: data?.advertiserId ?? "",
      startDate: data?.startDate,
    };
    if (data["BeeswaxCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        display: {
          continents: data?.BeeswaxCampaign?.continent ?? "",
          budgetType: data?.BeeswaxCampaign?.budgetType ?? "",
          budgetValue: data?.BeeswaxCampaign?.budget ?? null,
        },
      };
    }
    if (data["BeeswaxCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        linkedin: {
          objectiveTypeId: data?.LinkedinCampaign?.objectiveTypeId,
        },
      };
    }

    return editDefaultValues;
  }

  return {
    name: "",
    advertiserCompanyId: loggedInAdvertiserId ?? "",
    startDate: dayjs(),
  };
}
