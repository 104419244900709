import { FavFileExtension } from "@/types";

export default function getUrl({
  appName,
  name,
  fileExtension,
  srcUrl = "src/assets/app-fav",
}: {
  appName: string;
  name: string;
  srcUrl?: string;
  fileExtension: FavFileExtension;
}) {
  // passing srcUrl doesnt work for chrome for some reason i.e why it is hardcoded
  return new URL(
    `/src/assets/app-fav/${appName}/${name}.${fileExtension}`,
    import.meta.url
  ).href;
}
