import { getObjectiveTypes } from "@/apis/v1/leadGen";
import {
  getLinkedinAuthLink,
  getLinkedinBillingAdmin,
  getAdFacets,
  getAudienceById,
  getLinkedinCampaign,
  getPages,
  getLinkedinAudiences,
  runAudience,
  getAdAccounts,
} from "@/apis/v1/linkedin";
import { useMutation, useQuery } from "@tanstack/react-query";
export const TITLES_AD_FACETS_KEY = (key: string) => [
  "facetType",
  "titles",
  key,
];
export const LOCATIONS_AD_FACETS_KEY = (key: string) => [
  "facetType",
  "locations",
  key,
];
export const SKILLS_AD_FACETS_KEY = (key: string) => [
  "facetType",
  "skills",
  key,
];
export function useLinkedinAuthLink() {
  return useQuery(["linkedin", "auth-link"], getLinkedinAuthLink, {
    enabled: false,
  });
}
export function useLinkedinBillingAdmin() {
  return useQuery(["linkedin", "billing-admin"], getLinkedinBillingAdmin);
}
export function useLinkedinAdvertisers() {
  return useQuery(["linkedin", "advertiser"], getLinkedinBillingAdmin);
}
export function useJobFunctionsList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "jobFunctions"], getAdFacets, {
    enabled,
    meta: {
      facetType: "jobFunctions",
    },
  });
}
export function useCountriesList({
  enabled,
  search,
}: {
  enabled: boolean;
  search: string;
}) {
  return useQuery(["facetType", "locations"], getAdFacets, {
    enabled,
    meta: {
      queryParams: {
        search,
      },
      facetType: "locations",
    },
  });
}
export function useJobLevelList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "seniorities"], getAdFacets, {
    enabled,
    meta: {
      facetType: "seniorities",
    },
  });
}
export function useIndustriesList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "industries"], getAdFacets, {
    enabled,
    meta: {
      facetType: "industries",
    },
  });
}
export function useAgeRangesList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "ageRanges"], getAdFacets, {
    enabled,
    meta: {
      facetType: "ageRanges",
    },
  });
}
export function useGenderList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "genders"], getAdFacets, {
    enabled,
    meta: {
      facetType: "genders",
    },
  });
}
export function useSkillsList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "skills"], getAdFacets, {
    enabled,
    meta: {
      facetType: "skills",
    },
  });
}
export function useYearOfExperienceList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "yearsOfExperienceRanges"], getAdFacets, {
    enabled,
    meta: {
      facetType: "yearsOfExperienceRanges",
    },
  });
}
export function useTitleList({ enabled }: { enabled: boolean }) {
  return useQuery(["facetType", "titles"], getAdFacets, {
    enabled,
    meta: {
      queryParams: {
        search: "a",
      },
      facetType: "titles",
    },
  });
}

export function useGetAudienceById({
  id,
  isEnabled,
}: {
  id: any;
  isEnabled: boolean;
}) {
  return useQuery(["audiences", "get", id], getAudienceById, {
    enabled: isEnabled,
    meta: {
      id,
    },
  });
}

export function useLinkedinLineItem({
  id,
  enabled = true,
  onSuccess,
  select,
}: {
  id: any;
  enabled?: boolean;
  onSuccess?: (data: any) => void;
  select?: (data: any) => any;
}) {
  return useQuery(["linkdein", "lineItem", id], getLinkedinCampaign, {
    enabled,
    onSuccess,
    select,
    meta: {
      id,
    },
  });
}

export function useLinkedinPages(args?: { enabled?: boolean }) {
  return useQuery(["linkdein", "pages"], getPages, {
    enabled: args?.enabled ?? true,
  });
}
export function useLinkedinObjectiveTypes() {
  return useQuery(["linkdein", "objectiveTypes"], getObjectiveTypes);
}

export function useLinkedinAudiences({
  type,
  enabled,
}: {
  type: string;
  enabled: boolean;
}) {
  return useQuery(["linkdein", "audiences"], getLinkedinAudiences, {
    enabled,
    meta: { queryParams: { type } },
  });
}
export function useLinkedinAdAccounts(args?: { enabled?: boolean }) {
  return useQuery(["adAccounts"], getAdAccounts, {
    enabled: args?.enabled ?? true,
  });
}
export function runLinkedinAudience() {
  return useMutation(runAudience);
}
