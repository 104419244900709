import routes from "@/routes";
import { lazy } from "react";
import DisplayLeadGen from "./displayLeadGen.routes";
import LinkedinLeadGen from "./linkedinLeadGen.routes";
const Lead = lazy(() => import("@/modules/Asset/LeadGen"));

const LeadGenRoutes = {
  element: <Lead />,
  path: routes.ASSETS.LEADGEN.INDEX,

  children: [LinkedinLeadGen, DisplayLeadGen],
};

export default LeadGenRoutes;
