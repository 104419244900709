import { Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { VariableSizeList as List, VariableSizeList } from "react-window";
const PADDING = 8;

function renderRow(props: any) {
  const { data, index, style } = props;
  const dataSet = data[index][0];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + PADDING,
  };

  return (
    <Typography component="li" {...dataSet.props} noWrap style={inlineStyle}>
      {`${dataSet.option.label ?? dataSet.option}`}
    </Typography>
  );
}
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(
  function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  }
);

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const VirtualList = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function VirtualList(props, ref) {
  const { children: options, ...other } = props;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemSize = smUp ? 36 : 48;
  const itemCount = (options as any[]).length;
  const gridRef = useResetCache(itemCount);

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };
  return (
    <div ref={ref} {...other}>
      <OuterElementContext.Provider value={other}>
        <List
          height={getHeight() + 2 * PADDING}
          itemCount={itemCount}
          itemData={options ?? []}
          itemSize={() => itemSize}
          width="100%"
          ref={gridRef}
          overscanCount={5}
          outerElementType={OuterElementType}
          innerElementType="ul"
        >
          {renderRow}
        </List>
      </OuterElementContext.Provider>
    </div>
  );
});

export default VirtualList;
