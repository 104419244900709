import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { CustomDialogProps } from "../dialog.interface";

export default function DefaultDialog({
  dialogState,
  setDialogState,
}: CustomDialogProps) {
  const handleClose = () => {
    setDialogState({ open: false, title: "" });
    dialogState.onCloseAction && dialogState.onCloseAction();
  };
  return (
    <Dialog
      open={Boolean(dialogState.open && dialogState.title)}
      onClose={handleClose}
      fullWidth
      sx={dialogState.styles ?? {}}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.main",
          color: "common.white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {dialogState.title}
        <CloseOutlinedIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </DialogTitle>
      <Box sx={{ padding: "30px 30px", overflow: "auto" }}>
        {React.cloneElement(
          dialogState.children ?? <></>,
          dialogState.childProps
        )}
      </Box>
      {dialogState.buttonName && (
        <DialogActions>
          <CustomLoadingButton
            isLoading={dialogState.buttonLoading ?? false}
            title={dialogState.buttonName}
            onClick={() =>
              dialogState.buttonFunction?.(dialogState?.buttonFunctionParams)
            }
          />
        </DialogActions>
      )}
    </Dialog>
  );
}
