import { BORDER_RADIUS } from "@/constants";
import { Theme } from "@mui/material";
import DialogContent from "./dialogContent";

const Dialog = (theme: Theme) => ({
  MuiDialog: {
    styleOverrides: {
      // Name of the slot
      root: {
        borderRadius: BORDER_RADIUS,
      },
      paper: {
        borderRadius: BORDER_RADIUS,
        maxWidth: "734px",
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  ...DialogContent(theme),
});

export default Dialog;
