import { ReactComponent as DisplayLogo } from "@/assets/dashboard/common/display.svg";
import CardExpandable from "@/components/Card/CardExpandable";
import CustomSelect from "@/components/Form/InputFields/CustomSelect";
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { useLinkedinObjectiveTypes } from "@/modules/Linkedin/linkedin.hook";
import { Box, Grid } from "@mui/material";
import { campaignTypeList } from "./data";

export default function LinkedinForm({
  disabled,
  linkedinSwitch,
  setLinkedinSwitch,
  control,
  errors,
  isEdit,
  LinkedinCampaign,
}: any) {
  const { data: objectiveTypes, isLoading: objectiveTypesLoading } =
    useLinkedinObjectiveTypes();
  return (
    <CardExpandable
      title="LINKEDIN"
      Icon={DisplayLogo}
      isChecked={true}
      disabled={disabled}
      check={linkedinSwitch}
      setCheck={setLinkedinSwitch}
    >
      <Box sx={{ py: 3 }}>
        <Grid
          container
          columnSpacing={FORM_COLUMN_SPACING}
          rowSpacing={FORM_ROW_SPACING}
        >
          <Grid item xs={12}>
            <CustomSelect
              name="linkedin.objectiveTypeId"
              label="Select Campaign Objective"
              control={control}
              itemList={campaignTypeList(objectiveTypes)}
              error={errors?.linkedin?.objectiveTypeId?.message}
              required={true}
              loading={objectiveTypesLoading}
              type="secondary"
              disabled={!linkedinSwitch || (isEdit && LinkedinCampaign)}
            />
          </Grid>
        </Grid>
      </Box>
    </CardExpandable>
  );
}
