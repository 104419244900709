import { IUserLoginResponse } from "@/modules/User/user.interface";
import client from "../client";

export function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return client.post<IUserLoginResponse>("/admin/auth/signin", {
    email,
    password,
  });
}
