import CreateOrEditCampaign from "@/modules/Campaign/CreateOrEditCampaign";
import routes from "@/routes";
import { lazy } from "react";
import LineItemRoutes from "./lineItem.routes";
const Campaign = lazy(() => import("@/modules/Campaign"));
const ListCampaign = lazy(() => import("@/modules/Campaign/ListCampaign"));
const DetailCampaign = lazy(() => import("@/modules/Campaign/DetailCampaign"));

const CampaignRoutes = {
  element: <Campaign />,
  path: routes.CAMPAIGNS.INDEX,

  children: [
    {
      element: <ListCampaign />,
      path: "",
    },
    {
      element: <CreateOrEditCampaign />,
      path: routes.CAMPAIGNS.CREATE,
    },
    {
      element: <CreateOrEditCampaign isEdit />,
      path: routes.CAMPAIGNS.EDIT(),
    },
    {
      element: <DetailCampaign />,
      path: routes.CAMPAIGNS.VIEW(),
    },
    LineItemRoutes,
  ],
};

export default CampaignRoutes;
