import CardContainer from "@/components/Card/CardContainer";
import { useUserContext } from "@/context/UserContext";
import { Container } from "@mui/material";
import { useState } from "react";
import { useAgencyOrAdvertiserById } from "../Company/company.hook";
import AccountDetail from "./Detail";
import AccountForm from "./Form";

const PAGE = ["Detail", "Form"] as const;
const PAGE_COMPONENT = {
  [PAGE[0]]: AccountDetail,
  [PAGE[1]]: AccountForm,
};
export type AccountPage = (typeof PAGE)[number];

export default function AccountInfo() {
  const { user } = useUserContext();
  const { data: company } = useAgencyOrAdvertiserById({ id: user.companyId });
  const [activePage, setActivePage] = useState<AccountPage>("Detail");

  const data =
    user && company
      ? [
          {
            label: "Username",
            value: user.username,
          },
          {
            label: "Email",
            value: user.email,
          },
          {
            label: "Company",
            value: company.name,
          },
        ]
      : [];

  const Component = PAGE_COMPONENT[activePage];

  return (
    <Container maxWidth="md">
      <CardContainer sx={{ px: 4, py: 3, gap: 6 }}>
        <Component data={data} setActivePage={setActivePage} />
      </CardContainer>
    </Container>
  );
}
